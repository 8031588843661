import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as suppliersActions from './actions';
import {
  getFirestore,
  query,
  collection,
  doc,
  where,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

function* createSupplierSaga({ supplier }) {
  try {
    const db = getFirestore(rsf.app);
    const suppliersRef = collection(db, 'suppliers');
    const companyId = yield select((state) => state.Dashboard.companyId);

    yield call(rsf.firestore.addDocument, suppliersRef, {
      ...supplier,
      companyId,
      createdAt: serverTimestamp(),
    });
    yield put(suppliersActions.createSupplierSuccess(supplier));
    toastr.success('Supplier created!', '');
  } catch (error) {
    yield put(suppliersActions.createSupplierFailure(error));
  }
}

function* updateSupplierSaga({ supplier }) {
  try {
    const supplierRef = doc(db, 'suppliers', supplier.id);
    delete supplier.id;

    yield call(
      rsf.firestore.setDocument,
      supplierRef,
      {
        ...supplier,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(suppliersActions.updateSupplierSuccess(supplier));
    toastr.success('Supplier updated!', '');
  } catch (error) {
    yield put(suppliersActions.updateSupplierFailure(error));
  }
}

const supplierTransformer = (payload) => {
  let suppliers = [];

  payload.forEach((supplier) => {
    const data = supplier.data();

    suppliers.push({
      id: supplier.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(supplier, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(supplier, data, 'updatedAt').toDate(),
      }),
    });
  });

  return suppliers;
};

function* syncSuppliersSaga() {
  const companyId = yield select((state) => state.Dashboard.companyId);
  const suppliersRef = query(
    collection(db, 'suppliers'),
    where('companyId', '==', companyId),
    orderBy('name', 'asc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, suppliersRef, {
    successActionCreator: suppliersActions.syncSuppliersSuccess,
    failureActionCreator: suppliersActions.syncSuppliersFailure,
    transform: (payload) => supplierTransformer(payload),
  });

  yield take(types.RESET_SUPPLIER_STATE);
  yield cancel(task);
}

function* supplierSaga() {
  yield all([
    takeLatest(types.SYNC_SUPPLIERS.REQUEST, syncSuppliersSaga),
    takeLeading(types.CREATE_SUPPLIER.REQUEST, createSupplierSaga),
    takeLeading(types.UPDATE_SUPPLIER.REQUEST, updateSupplierSaga),
  ]);
}

export default supplierSaga;
