import types from './actionTypes';

const initialState = {
  loading: false,
  companies: [],
  error: '',
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_COMPANY_STATE:
      return initialState;
    case types.CREATE_COMPANY.REQUEST:
    case types.UPDATE_COMPANY.REQUEST:
    case types.SYNC_COMPANIES.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_COMPANY.SUCCESS:
    case types.UPDATE_COMPANY.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_COMPANIES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        companies: [...action.companies],
      };
    case types.CREATE_COMPANY.FAILURE:
    case types.UPDATE_COMPANY.FAILURE:
    case types.SYNC_COMPANIES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default companyReducer;
