import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as peopleActions from '../../store/people/actions';
import * as audiencesActions from '../../store/audiences/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  countPeople,
  countCertifiedPeople,
  countPeopleWithMarketingConsent,
  countPeopleWithThirdPartyConsent,
  countPeopleWithPushTokens,
} from '../../selectors/person';
import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import MiniCard from '../../components/Common/MiniCard';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/People/tableColumns';
import {
  CountRowTablePlacement,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const List = (props) => {
  const people = useSelector((state) => state.People.people);
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );
  const audiences = useSelector((state) => state.Audience.audiences);
  const loading = useSelector((state) => state.People.loading);

  const totalPeople = useSelector(countPeople);

  const certifiedPeople = useSelector(countCertifiedPeople);
  const percentageCertifiedPeople = (certifiedPeople / totalPeople) * 100;

  const peopleWithMarketingConsent = useSelector(
    countPeopleWithMarketingConsent,
  );
  const percentagePeopleWithMarketingConsent =
    (peopleWithMarketingConsent / totalPeople) * 100;

  const peopleWithThirdPartyConsent = useSelector(
    countPeopleWithThirdPartyConsent,
  );
  const percentagePeopleWithThirdPartyConsent =
    (peopleWithThirdPartyConsent / totalPeople) * 100;

  const peopleWithPushTokens = useSelector(countPeopleWithPushTokens);
  const percentagePeopleWithPushTokens =
    (peopleWithPushTokens / totalPeople) * 100;

  const [selectedPerson, setSelectedPerson] = useState(null);
  const [disablePersonAlert, setDisablePersonAlert] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const startDate = useSelector((state) => state.People.startDate);
  const endDate = useSelector((state) => state.People.endDate);
  const dataFilters = useSelector((state) => state.People.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('04/07/2020', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    audienceIds: dataFilters?.audienceIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, audienceIds }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      peopleActions.fetchPeople(selectedStartDate, selectedEndDate, {
        audienceIds,
      }),
    );
    setSelectedFilters({ startDate, endDate, audienceIds });
  });

  useEffect(() => {
    people.length === 0 && refresh(selectedFilters);
    dispatch(audiencesActions.syncAudiences());
  }, []);

  const openViewModal = (person) => {
    setSelectedPerson(person);
    setViewModal(true);
  };

  const { t } = useTranslation();

  const metrics = [
    {
      title: t('People'),
      text: totalPeople,
      color: 'dark',
      icon: 'bx bx-street-view',
    },
    {
      title: t('Certified People'),
      text: certifiedPeople,
      color: 'success',
      icon: 'bx bx-street-view',
      percentage: `${
        isFinite(percentageCertifiedPeople)
          ? percentageCertifiedPeople.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('People With Marketing Consent'),
      text: peopleWithMarketingConsent,
      color: 'primary',
      icon: 'bx bx-fingerprint',
      percentage: `${
        isFinite(percentagePeopleWithMarketingConsent)
          ? percentagePeopleWithMarketingConsent.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('People With Third Party Consent'),
      text: peopleWithThirdPartyConsent,
      color: 'primary',
      icon: 'bx bx-fingerprint',
      percentage: `${
        isFinite(percentagePeopleWithThirdPartyConsent)
          ? percentagePeopleWithThirdPartyConsent.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('People With Push Notifications'),
      text: peopleWithPushTokens,
      color: 'info',
      icon: 'bx bx-notification',
      percentage: `${
        isFinite(percentagePeopleWithPushTokens)
          ? percentagePeopleWithPushTokens.toFixed(0)
          : 0
      }%`,
    },
  ];

  const openDisablePersonAlert = (person) => {
    setSelectedPerson(person);
    setDisablePersonAlert(true);
  };

  const handleDisablePerson = () => {
    dispatch(
      peopleActions.updatePerson({
        id: selectedPerson.id,
        disabled: true,
      }),
    );
    setDisablePersonAlert(false);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        audiences,
        openViewModal,
        openDisablePersonAlert,
        showSensitiveData,
      ),
    [audiences, showSensitiveData],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="People"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showAudienceFilters
            initialFilters={selectedFilters}
          />
          <Row>
            {metrics.map((metric, index) => (
              <Col key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={people}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Person"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedPerson}
              icon="bx bx-street-view"
            />
          </Modal>
          <Alert
            isOpen={disablePersonAlert}
            title="Do you want disable this Person?"
            confirmBtnLabel="Disable"
            onCancel={() => setDisablePersonAlert(false)}
            onConfirm={handleDisablePerson}>
            Keep attention!
          </Alert>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
