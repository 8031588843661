import { takeLeading, select, put, all, call } from 'redux-saga/effects';
import types from './actionTypes';
import * as dailyInsightsActions from './actions';
import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import { selectCampaignsByPublisher } from '../../../selectors/campaign';
import {
  toDateFirebase,
  arrayChunks,
  isNullish,
} from '../../../helpers/sharedFunction';

export const dailyInsightTransformer = (insight, data) => {
  const date = toDateFirebase(insight, data, 'date').toDate();
  const workedLeads =
    data.positiveLeads +
    data.negativeLeads +
    data.fakeLeads +
    data.leadsNoTiming +
    data.refusedLeads;
  const advCosts = data.fbCosts + data.googleCosts;
  const totalSupplierCosts = data.totalSupplierCosts || 0;
  const totalCosts = data.qualifiedCosts + advCosts + totalSupplierCosts;
  const margin = data.revenues - totalCosts;
  const eCPA = isFinite((advCosts + totalSupplierCosts) / data.leads)
    ? (advCosts + totalSupplierCosts) / data.leads
    : 0;
  const eCPQ = isFinite(data.qualifiedCosts / data.leads)
    ? data.qualifiedCosts / data.leads
    : 0;
  const eRPL = isFinite(data.revenues / data.leads)
    ? data.revenues / data.leads
    : 0;

  return {
    id: insight.id,
    ...data,
    workedLeads,
    date,
    advCosts,
    totalSupplierCosts,
    totalCosts,
    margin,
    eCPA,
    eCPQ,
    eRPL,
  };
};

function* fetchDailyInsightsSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    let dailyInsightsSnaps = null;

    const db = getFirestore(rsf.app);
    const queryConditions = [
      where('date', '>=', startDate),
      where('date', '<=', endDate),
      where('companyId', '==', companyId),
      orderBy('date', 'desc'),
    ];

    if (!filters || isNullish(filters)) {
      dailyInsightsSnaps = yield call(
        rsf.firestore.getCollection,
        query(collection(db, 'dailyCampaignInsights'), ...queryConditions),
      );
      dailyInsightsSnaps = [dailyInsightsSnaps];
    } else {
      let campaigns = null;
      if (filters.supplierId && !filters.campaignIds) {
        campaigns = yield select(
          selectCampaignsByPublisher(filters.supplierId),
        );
        campaigns = campaigns.map((campaign) => campaign.id);
        campaigns = arrayChunks(campaigns, 10);
      } else campaigns = arrayChunks(filters.campaignIds, 10);

      dailyInsightsSnaps = yield all(
        campaigns.map((campaignId) => {
          queryConditions.push(where('campaignId', 'in', campaignId));
          return call(
            rsf.firestore.getCollection,
            query(collection(db, 'dailyCampaignInsights'), ...queryConditions),
          );
        }),
      );
    }

    let dailyInsights = [];

    dailyInsightsSnaps.forEach((dailyInsightsSnap) => {
      dailyInsightsSnap.forEach((insight) => {
        const data = insight.data();
        data.supplierId !== null &&
          dailyInsights.push(dailyInsightTransformer(insight, data));
      });
    });

    yield put(
      dailyInsightsActions.fetchDailyInsightsSuccess(
        dailyInsights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(dailyInsightsActions.fetchDailyInsightsFailure(error));
  }
}

function* dailyInsightsSaga() {
  yield all([
    takeLeading(types.DAILY_PUBLISHER_INSIGHTS.REQUEST, fetchDailyInsightsSaga),
  ]);
}

export default dailyInsightsSaga;
