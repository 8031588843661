import {
  getFirestore,
  collection,
  doc,
  query,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  where,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';
import { buffers, eventChannel } from 'redux-saga';
import { call, fork } from 'redux-saga/effects';
import { syncChannel } from './utils';

import { isNullish } from '../../helpers/sharedFunction';

export const getCollectionRef = (rsf, pathOrRef) =>
  typeof pathOrRef === 'string'
    ? collection(getFirestore(rsf.app), pathOrRef)
    : pathOrRef;

export const getDocumentRef = (rsf, pathOrRef) =>
  typeof pathOrRef === 'string'
    ? doc(getFirestore(rsf.app), pathOrRef)
    : pathOrRef;

function* addDocument(collectionRef, data) {
  const collection = getCollectionRef(this, collectionRef);
  return yield call(addDoc, collection, data);
}

function channel(pathOrRef, type = 'collection', buffer = buffers.none()) {
  const ref =
    type === 'collection'
      ? getCollectionRef(this, pathOrRef)
      : getDocumentRef(this, pathOrRef);

  const channel = eventChannel((emit) => {
    const unsubscribe = onSnapshot(ref, emit);

    // Returns unsubscribe function
    return unsubscribe;
  }, buffer);

  return channel;
}

function* deleteDocument(documentRef) {
  const doc = getDocumentRef(this, documentRef);
  return yield call(deleteDoc, doc);
}

function* getCollection(collectionRef) {
  const collection = getCollectionRef(this, collectionRef);
  return yield call(getDocs, collection);
}

function* getDocument(documentRef) {
  const doc = getDocumentRef(this, documentRef);
  return yield call(getDoc, doc);
}

function* setDocument(documentRef, data, options) {
  const doc = getDocumentRef(this, documentRef);
  return yield call(setDoc, doc, data, options);
}

function* updateDocument(documentRef, ...args) {
  const doc = getDocumentRef(this, documentRef);
  // @ts-ignore
  return yield call(updateDoc, doc, ...args);
}

function* syncCollection(pathOrRef, options) {
  const channel = yield call(this.firestore.channel, pathOrRef, 'collection');
  yield fork(syncChannel, channel, options);
}

function* syncDocument(pathOrRef, options) {
  const channel = yield call(this.firestore.channel, pathOrRef, 'document');
  yield fork(syncChannel, channel, options);
}

function createCollectionRefWithFilters(
  collectionName,
  companyId,
  startDate,
  endDate,
  filters = null,
) {
  const db = getFirestore(this.app);
  const queryConditions = [
    where('createdAt', '>=', startDate),
    where('createdAt', '<=', endDate),
    where('companyId', '==', companyId),
    orderBy('createdAt', 'desc'),
  ];

  if (!filters || isNullish(filters))
    return query(collection(db, collectionName), ...queryConditions);

  if (filters.sector && !filters.campaignIds) {
    queryConditions.push(where('sector', '==', filters.sector));
    return query(collection(db, collectionName), ...queryConditions);
  }

  queryConditions.push(where('campaignId', 'in', filters.campaignIds));
  return query(collection(db, collectionName), ...queryConditions);
}

export default {
  addDocument,
  channel,
  deleteDocument,
  getCollection,
  getDocument,
  setDocument,
  syncCollection,
  syncDocument,
  updateDocument,
  createCollectionRefWithFilters,
};
