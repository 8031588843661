import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
} from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';

import * as messageTemplatesActions from '../../store/messageTemplates/actions';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';
import { selectMonetizationOrdersBySendLeadType } from '../../selectors/monetizationOrder';
import { useDispatch, useSelector } from 'react-redux';

import { SubmitButton } from '../Common/Modal/modalActionButtons';
import messageTemplates from '../../config/messageTemplates';
import toastr from 'toastr';

const HTTPS_REGEXP =
  /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const FormMessageTemplate = (props) => {
  const editingMessageTemplate = props.messageTemplate || {};

  const monetizationOrders = useSelector(
    selectMonetizationOrdersBySendLeadType('trackingPixel'),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
  }, []);

  const handleSubmit = (values) => {
    if (
      values.templateType === 'pushNotification' &&
      values.messageText.includes('{{link}}')
    )
      return toastr.error(
        'Push Notification must not include a LINK macro!',
        'Error',
      );

    dispatch(
      props.create
        ? messageTemplatesActions.createMessageTemplate(values)
        : messageTemplatesActions.updateMessageTemplate({
            id: editingMessageTemplate.id,
            ...values,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={editingMessageTemplate}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="4">
                  <Input type="text" name="name" id="name" />
                </Col>
                <Col md="3">
                  <Select
                    name="templateType"
                    id="templateType"
                    label="Type"
                    isMulti={false}
                    options={messageTemplates}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Input
                    type="text"
                    name="subject"
                    id="subject"
                    label={
                      values.templateType === 'pushNotification'
                        ? 'Title'
                        : 'Subject'
                    }
                  />
                </Col>
                <Col md="6">
                  <Input
                    type="textarea"
                    name="messageText"
                    id="messageText"
                    label="Message"
                  />
                  {values.templateType === 'pushNotification' && (
                    <p className="font-size-12 text-muted">
                      {`Message text must not contain {{link}} macro`}
                    </p>
                  )}
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="messageLength">Message Length</Label>
                    <p>
                      <strong>
                        {values.messageText ? values.messageText.length : 0}
                      </strong>
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Collapse isOpen={values.templateType === 'pushNotification'}>
                <Row>
                  <Col md="4">
                    <Input
                      type="text"
                      name="pushIcon"
                      id="pushIcon"
                      label="URL Icon"
                    />
                    <p className="font-size-12 text-muted">
                      Blank for Chiedimi Icon
                    </p>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      name="clickAction"
                      id="clickAction"
                      label="Click Action"
                    />
                    <p className="font-size-12 text-muted">
                      {`Insert URL or '{{link}} for survey link or tracking link`}
                    </p>
                  </Col>
                </Row>
              </Collapse>
              <Collapse
                isOpen={
                  (values.messageText &&
                    values.messageText.includes('{{link}}')) ||
                  values.clickAction === '{{link}}'
                }>
                <Row>
                  <Col md="4">
                    <Select
                      name="linkType"
                      id="linkType"
                      label="Link Type"
                      isMulti={false}
                      options={[
                        { value: 'surveyForm', label: 'Survey Form' },
                        { value: 'trackingLink', label: 'Tracking Link' },
                      ]}
                    />
                  </Col>
                  <Col md="8">
                    <Collapse isOpen={values.linkType === 'trackingLink'}>
                      <Select
                        name="monetizationOrderId"
                        id="monetizationOrderId"
                        label="Monetization Order"
                        isMulti={false}
                        options={monetizationOrders.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))}
                      />
                    </Collapse>
                  </Col>
                </Row>
              </Collapse>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormMessageTemplate;
