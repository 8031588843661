import React from 'react';
import { Media } from 'reactstrap';
import { useSelector } from 'react-redux';
import { nextCallTime, countMakeCallsNow } from '../../selectors/call';

import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';
import { dateToTimeString } from '../../helpers/sharedFunction';

const NextCallTime = (props) => {
  const user = useSelector((state) => state.Auth.admin);
  const userId = user.role === roles.OPERATOR ? user.id : null;

  const nextCall = useSelector(nextCallTime(userId));
  const makeCalls = useSelector(countMakeCallsNow(userId));
  const loading = useSelector((state) => state.Call.loading);

  const { t } = useTranslation();

  return (
    !loading &&
    makeCalls === 0 &&
    nextCall && (
      <div className="d-inline-block mr-4 mini-stats-wid">
        <Media className="header-item">
          <div className="align-self-center mr-2">
            <span className="avatar-xs rounded-circle">
              <i className="bx bxs-info-circle font-size-22 text-info"></i>
            </span>
          </div>
          <div className="align-self-center mr-3">
            <h5 className="font-size-15 mt-0 mb-1">
              {t('Next call at')} {dateToTimeString(nextCall)}
            </h5>
          </div>
        </Media>
      </div>
    )
  );
};

export default NextCallTime;
