import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as acquisitionOrdersActions from './actions';
import * as campaignsOrdersActions from '../campaigns/actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

function* createAcquisitionOrderSaga({ acquisitionOrder }) {
  try {
    const acquisitionOrdersRef = collection(db, 'acquisitionOrders');
    const companyId = yield select((state) => state.Dashboard.companyId);

    const newAcquisitionOrder = yield call(
      rsf.firestore.addDocument,
      acquisitionOrdersRef,
      {
        ...acquisitionOrder,
        companyId,
        createdAt: serverTimestamp(),
      },
    );
    yield put(
      campaignsOrdersActions.updateOrderAcquisitionIdCampaign(
        acquisitionOrder.campaignIds,
        [],
        newAcquisitionOrder.id,
      ),
    );
    yield put(
      acquisitionOrdersActions.createAcquisitionOrderSuccess(acquisitionOrder),
    );
    toastr.success('AcquisitionOrder created!', '');
  } catch (error) {
    yield put(acquisitionOrdersActions.createAcquisitionOrderFailure(error));
  }
}

function* updateAcquisitionOrderSaga({ acquisitionOrder, prevCampaignIds }) {
  try {
    const acquisitionOrderRef = doc(
      collection(db, 'acquisitionOrders'),
      acquisitionOrder.id,
    );
    const newAcquisitionOrder = Object.assign({}, acquisitionOrder);
    delete newAcquisitionOrder.id;

    //console.log('##', newAcquisitionOrder.jsonLogicQuery);

    yield call(rsf.firestore.setDocument, acquisitionOrderRef, {
      ...newAcquisitionOrder,
      createdAt: Timestamp.fromDate(newAcquisitionOrder.createdAt),
      updatedAt: serverTimestamp(),
    });
    yield put(
      campaignsOrdersActions.updateOrderAcquisitionIdCampaign(
        acquisitionOrder.campaignIds,
        prevCampaignIds,
        acquisitionOrder.id,
      ),
    );
    yield put(acquisitionOrdersActions.updateAcquisitionOrderSuccess());
    toastr.success('AcquisitionOrder updated!', '');
  } catch (error) {
    yield put(acquisitionOrdersActions.updateAcquisitionOrderFailure(error));
  }
}

const acquisitionOrderTransformer = (payload) => {
  let acquisitionOrders = [];

  payload.forEach((acquisitionOrder) => {
    const data = acquisitionOrder.data();

    acquisitionOrders.push({
      id: acquisitionOrder.id,
      ...data,
      createdAt: toDateFirebase(payload, data).toDate(),
    });
  });

  return acquisitionOrders;
};

function* syncAcquisitionOrdersSaga() {
  const companyId = yield select((state) => state.Dashboard.companyId);
  const acquisitionOrdersRef = query(
    collection(db, 'acquisitionOrders'),
    where('companyId', '==', companyId),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, acquisitionOrdersRef, {
    successActionCreator: acquisitionOrdersActions.syncAcquisitionOrdersSuccess,
    failureActionCreator: acquisitionOrdersActions.syncAcquisitionOrdersFailure,
    transform: (payload) => acquisitionOrderTransformer(payload),
  });

  yield take(types.RESET_ACQUISITION_ORDER_STATE);
  yield cancel(task);
}

function* acquisitionOrderSaga() {
  yield all([
    takeLatest(
      types.SYNC_ACQUISITION_ORDERS.REQUEST,
      syncAcquisitionOrdersSaga,
    ),
    takeLeading(
      types.CREATE_ACQUISITION_ORDER.REQUEST,
      createAcquisitionOrderSaga,
    ),
    takeLatest(
      types.UPDATE_ACQUISITION_ORDER.REQUEST,
      updateAcquisitionOrderSaga,
    ),
  ]);
}

export default acquisitionOrderSaga;
