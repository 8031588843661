import React from 'react';
import {
  getFilter,
  getLabel,
  userName,
} from '../Common/DataTable/tableHelpers';
import { capitalize } from '../../helpers/sharedFunction';
import moment from 'moment';

const createTableColumns = (
  acquisitionOrders,
  monetizationOrders,
  users,
  t,
) => [
  {
    title: 'Acquisition Order',
    name: 'acquisitionOrderId',
    getCellValue: (row) => getLabel(row.acquisitionOrderId, acquisitionOrders),
  },
  {
    title: 'Closure',
    width: 160,
    name: 'closure',
    getCellValue: (row) =>
      row.revenue > 0 && row.status === 'closed' ? 'positive' : 'negative',
    formatterComponent: ({ value }) =>
      value === 'positive' ? (
        <span className="badge badge-success font-size-12">
          {t('Positive Closure')}
        </span>
      ) : (
        <span className="badge badge-danger font-size-12">
          {t('Negative Closure')}
        </span>
      ),
    sorting: 'disabled',
  },
  {
    title: 'Closure At',
    name: 'createdAt',
    width: 250,
    formatterComponent: ({ value }) => t('date_fromNow', { date: value }),
    filter: (value, filter) =>
      getFilter(t('date_fromNow', { date: value }), filter.value),
    exportValue: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    title: 'Monetization Order',
    name: 'monetizationOrderId',
    getCellValue: (row) =>
      getLabel(row.monetizationOrderId, monetizationOrders),
  },
  {
    title: 'Outcome',
    formatterComponent: ({ row }) =>
      row.positiveOutcome || row.negativeOutcome
        ? t(capitalize(row.positiveOutcome || row.negativeOutcome))
        : '',
    filter: (value, filter, row) =>
      getFilter(
        row.positiveOutcome || row.negativeOutcome
          ? t(capitalize(row.positiveOutcome || row.negativeOutcome))
          : '',
        filter.value,
      ),
    sorting: 'disabled',
  },
  {
    title: 'Operator',
    width: 150,
    name: 'userId',
    getCellValue: (row) => userName(row.userId, users),
  },
];

export default createTableColumns;
