import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as customersActions from '../../store/customers/actions';
import * as suppliersActions from '../../store/suppliers/actions';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';

import { selectSupplierByType } from '../../selectors/supplier';
import { createQueryBuilderConfig } from '../../selectors/leadField';

import Modal from '../../components/Common/Modal/Modal';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormMonetizationOrder from '../../components/MonetizationOrders/FormMonetizationOrder';
import LeadsQueryTable from '../../components/Leads/LeadsQueryTable';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/MonetizationOrders/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import supplierTypes from '../../config/supplierTypes';

const List = (props) => {
  const loadingCustomers = useSelector((state) => state.Customer.loading);
  const loadingMonetizationOrders = useSelector(
    (state) => state.MonetizationOrder.loading,
  );
  const loading = loadingCustomers || loadingMonetizationOrders;

  const customers = useSelector((state) => state.Customer.customers);
  const sales = useSelector(selectSupplierByType(supplierTypes.SALE));
  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );
  const messageTemplates = useSelector(
    (state) => state.MessageTemplate.messageTemplates,
  );

  const leadQueryConfig = useSelector(createQueryBuilderConfig());

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [testQueryModal, setTestQueryModal] = useState(false);
  const [selectedMonetizationOrder, setSelectedMonetizationOrder] =
    useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
    dispatch(customersActions.syncCustomers());
    dispatch(suppliersActions.syncSuppliers());
  }, []);

  const openViewModal = (monetizationOrder) => {
    setSelectedMonetizationOrder(monetizationOrder);
    setViewModal(true);
  };

  const openEditModal = (monetizationOrder) => {
    setSelectedMonetizationOrder(monetizationOrder);
    setEditModal(true);
  };

  const openTestQueryModal = (monetizationOrder) => {
    setSelectedMonetizationOrder(monetizationOrder);
    setTestQueryModal(true);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        customers,
        sales,
        openViewModal,
        openEditModal,
        openTestQueryModal,
      ),
    [customers, sales],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Monetization Order" breadcrumbItem="List" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Monetization Order"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={monetizationOrders}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Monetization Order"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedMonetizationOrder} />
          </Modal>
          <Modal
            size="xl"
            title="Monetization Order"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormMonetizationOrder
              closeModal={() => setEditModal(false)}
              create={!selectedMonetizationOrder}
              loading={loadingCustomers || loadingMonetizationOrders}
              customers={customers}
              monetizationOrder={selectedMonetizationOrder}
              messageTemplates={messageTemplates}
            />
          </Modal>
          <Modal
            size="xl"
            title="Test Query Leads"
            isOpen={testQueryModal}
            toggle={() => setTestQueryModal(!testQueryModal)}
            showClose
            scrollable={false}>
            <LeadsQueryTable
              query={selectedMonetizationOrder?.jsonLogicQuery}
              queryConfig={leadQueryConfig}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
