import React from 'react';
import { Redirect } from 'react-router-dom';
import roles from '../config/roles';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Register from '../pages/Authentication/Register';
import Logout from '../pages/Authentication/Logout';
import ForgetPassword from '../pages/Authentication/ForgetPassword';

// Web Push related page
import WebPush from '../pages/WebPush';

// Opt-in, opt-out related page
import OptInOut from '../pages/OptInOut';

// All protected pages
import Dashboard from '../pages/Dashboard';
import LeadList from '../pages/Leads';
import CreateLead from '../pages/Leads/Create';
import LeadsFieldsList from '../pages/Leads/LeadFields';
import LeadsFormsList from '../pages/Leads/LeadForms';
import PersonList from '../pages/People/List';
import AudienceList from '../pages/Audiences/List';
import DailyFBCampaignInsights from '../pages/FacebookAd/DailyCampaignInsights';
import DailyGoogleCampaignInsights from '../pages/GoogleAd/DailyCampaignInsights';
import CustomerList from '../pages/Customers/List';
import DailyCustomerInsights from '../pages/Customers/DailyInsights';
import SupplierList from '../pages/Suppliers/List';
import DailySaleInsights from '../pages/Suppliers/DailySaleInsights';
import DailyPublisherInsights from '../pages/Suppliers/DailyPublisherInsights';
import AcquisitionOrdersList from '../pages/AcquisitionOrders/List';
import MonetizationOrdersList from '../pages/MonetizationOrders/List';
import DailyMonetizationOrderInsights from '../pages/MonetizationOrders/DailyInsights';
import MonetizationItemsList from '../pages/MonetizationItems/List';
import CampaignsList from '../pages/Campaigns/List';
import DailyCampaignInsights from '../pages/Campaigns/DailyInsights';
import MarketingCampaignsList from '../pages/MarketingCampaigns/List';
import SurveysList from '../pages/Surveys/List';
import SurveysFormsList from '../pages/Surveys/SurveyForms';
import MessageTemplateList from '../pages/MessageTemplates/List';
import SmsMessagesList from '../pages/SmsMessages/List';
import PushNotificationsList from '../pages/PushNotifications/List';
import CallsList from '../pages/Calls/List';
import ManageCall from '../pages/Calls/ManageCall';
import CallInsights from '../pages/Calls/Insights';
import UsersList from '../pages/Users/List';
import CompaniesList from '../pages/Companies/List';
import DailyCompanyInsights from '../pages/Companies/DailyInsights';

const userRoutes = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'bx bx-home-alt',
    component: Dashboard,
  },
  {
    group: 'Leads',
    icon: 'bx bx-user-pin',
    children: [
      {
        title: 'Leads',
        path: '/leads/list',
        component: LeadList,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.MANAGER,
          roles.PUBLISHER,
        ],
      },
      {
        title: 'Create Lead',
        path: '/leads/create',
        component: CreateLead,
        permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER],
      },
      {
        title: 'Lead Forms',
        path: '/leads/leadForms',
        component: LeadsFormsList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Lead Fields',
        path: '/leads/leadFields',
        component: LeadsFieldsList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    group: 'People',
    icon: 'bx bx-street-view',
    children: [
      {
        title: 'People',
        path: '/people/list',
        component: PersonList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Audiences',
        path: '/audiences/list',
        component: AudienceList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    group: 'Campaigns',
    icon: 'bx bx-gas-pump',
    children: [
      {
        title: 'Campaigns',
        path: '/campaigns/list',
        component: CampaignsList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Insights',
        path: '/campaigns/dailyInsights',
        component: DailyCampaignInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Marketing',
        path: '/marketingCampaigns/list',
        component: MarketingCampaignsList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    group: 'Calls',
    icon: 'bx bx-phone-call',
    children: [
      {
        title: 'Calls',
        path: '/calls/list',
        component: CallsList,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.MANAGER,
          roles.TEAM_LEADER,
        ],
      },
      {
        title: 'Call Center',
        path: '/calls/manage',
        component: ManageCall,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.MANAGER,
          roles.TEAM_LEADER,
          roles.OPERATOR,
        ],
      },
      {
        title: 'Insights',
        path: '/calls/insights',
        component: CallInsights,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.MANAGER,
          roles.TEAM_LEADER,
          roles.OPERATOR,
        ],
      },
    ],
  },
  {
    group: 'Customers',
    icon: 'bx bxs-building-house',
    children: [
      {
        title: 'Customers',
        path: '/customers/list',
        component: CustomerList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Insights',
        path: '/customers/dailyInsights',
        component: DailyCustomerInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.CUSTOMER],
      },
    ],
  },
  {
    group: 'Suppliers',
    icon: 'far fa-handshake',
    children: [
      {
        title: 'Suppliers',
        path: '/suppliers/list',
        component: SupplierList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Sale Insights',
        path: '/suppliers/dailySaleInsights',
        component: DailySaleInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.SALE],
      },
      {
        title: 'Publisher Insights',
        path: '/suppliers/dailyPublisherInsights',
        component: DailyPublisherInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.PUBLISHER],
      },
    ],
  },
  {
    group: 'Orders',
    icon: 'bx bx-wallet',
    children: [
      {
        title: 'Acquisition Orders',
        path: '/acquisitionOrders/list',
        component: AcquisitionOrdersList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Monetization Orders',
        path: '/monetizationOrders/list',
        component: MonetizationOrdersList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Monetization Items',
        path: '/monetizationItems/list',
        component: MonetizationItemsList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER],
      },
      {
        title: 'Monetization Insights',
        path: '/monetizationOrders/dailyInsights',
        component: DailyMonetizationOrderInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    group: 'Daily Insights',
    icon: 'bx bx-bar-chart-alt-2',
    children: [
      {
        title: 'Facebook',
        path: '/facebookAd/dailyCampaignInsights',
        component: DailyFBCampaignInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Google',
        path: '/googleAd/dailyCampaignInsights',
        component: DailyGoogleCampaignInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    group: 'Surveys',
    icon: 'mdi mdi-comment-question-outline',
    children: [
      {
        title: 'Survey Answers',
        path: '/surveys/list',
        component: SurveysList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Survey Forms',
        path: '/surveys/surveyForms',
        component: SurveysFormsList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    group: 'Messages',
    icon: 'bx bx-message-dots',
    children: [
      {
        title: 'Templates',
        path: '/messageTemplates/list',
        component: MessageTemplateList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'SMS',
        path: '/smsMessages/list',
        component: SmsMessagesList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Push Notifications',
        path: '/pushNotifications/list',
        component: PushNotificationsList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    title: 'Users',
    path: '/users/list',
    component: UsersList,
    icon: 'bx bx bx-user-circle',
    permission: [
      roles.SUPER_ADMIN,
      roles.ADMIN,
      roles.MANAGER,
      roles.TEAM_LEADER,
    ],
  },
  {
    group: 'Companies',
    icon: 'bx bxs-shapes',
    children: [
      {
        title: 'Companies',
        path: '/companies/list',
        component: CompaniesList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Insights',
        path: '/companies/dailyInsights',
        component: DailyCompanyInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forgot-password', component: ForgetPassword },
  { path: '/web-push', component: WebPush },
  { path: '/opt-in-out', component: OptInOut },
];

export { userRoutes, authRoutes };
