import { takeLeading, select, put, all, call } from 'redux-saga/effects';
import types from './actionTypes';
import * as dailyInsightsActions from './actions';
import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import { selectMonetizationOrdersBySale } from '../../../selectors/monetizationOrder';
import {
  toDateFirebase,
  arrayChunks,
  isNullish,
} from '../../../helpers/sharedFunction';

export const dailyInsightTransformer = (insight, data) => {
  const date = toDateFirebase(insight, data, 'date').toDate();
  const leads = data.positives + data.refused;
  const totalSupplierCosts = data.totalSupplierCosts || 0;
  const totalCosts =
    data.positiveQualifiedCosts + data.acquisitionCosts + totalSupplierCosts;
  const margin = data.revenues - totalCosts;

  return {
    id: insight.id,
    ...data,
    leads,
    date,
    totalSupplierCosts,
    totalCosts,
    margin,
  };
};

function* fetchDailyInsightsSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    let dailyInsightsSnaps = null;

    const db = getFirestore(rsf.app);
    const queryConditions = [
      where('date', '>=', startDate),
      where('date', '<=', endDate),
      where('companyId', '==', companyId),
      orderBy('date', 'desc'),
    ];

    if (!filters || isNullish(filters)) {
      dailyInsightsSnaps = yield call(
        rsf.firestore.getCollection,
        query(
          collection(db, 'dailyMonetizationOrderInsights'),
          ...queryConditions,
        ),
      );
      dailyInsightsSnaps = [dailyInsightsSnaps];
    } else {
      let monetizationOrders = null;
      if (filters.supplierId && !filters.monetizationOrderIds) {
        monetizationOrders = yield select(
          selectMonetizationOrdersBySale(filters.supplierId),
        );
        monetizationOrders = monetizationOrders.map(
          (monetizationOrder) => monetizationOrder.id,
        );
        monetizationOrders = arrayChunks(monetizationOrders, 10);
      } else monetizationOrders = arrayChunks(filters.monetizationOrderIds, 10);

      dailyInsightsSnaps = yield all(
        monetizationOrders.map((monetizationOrderId) => {
          queryConditions.push(
            where('monetizationOrderId', 'in', monetizationOrderId),
          );
          return call(
            rsf.firestore.getCollection,
            query(
              collection(db, 'dailyMonetizationOrderInsights'),
              ...queryConditions,
            ),
          );
        }),
      );
    }

    let dailyInsights = [];

    dailyInsightsSnaps.forEach((dailyInsightsSnap) => {
      dailyInsightsSnap.forEach((insight) => {
        const data = insight.data();
        data.supplierId !== null &&
          dailyInsights.push(dailyInsightTransformer(insight, data));
      });
    });

    yield put(
      dailyInsightsActions.fetchDailyInsightsSuccess(
        dailyInsights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(dailyInsightsActions.fetchDailyInsightsFailure(error));
  }
}

function* dailyInsightsSaga() {
  yield all([
    takeLeading(types.DAILY_SALE_INSIGHTS.REQUEST, fetchDailyInsightsSaga),
  ]);
}

export default dailyInsightsSaga;
