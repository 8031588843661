import React, { useEffect, useCallback } from 'react';
import { useStopwatch } from 'react-timer-hook';

import { selectLead } from '../../selectors/lead';
import { selectAcquisitionOrdersByLead } from '../../selectors/acquisitionOrder';
import { useSelector } from 'react-redux';
import Timer from '../../lib/stopwatch/Timer';
import colors from '../CommonStyle/colors';

const stopwatchColor = (minutes, seconds, isRunning, callStopwatchTime) => {
  const timeToFloat = minutes + seconds / 60;
  if (timeToFloat > callStopwatchTime * 1.5) return colors.danger;
  if (timeToFloat > callStopwatchTime) return colors.warning;
  if (isRunning) return colors.success;
  return colors.secondary;
};

const CallStopwatch = (props) => {
  const call = useSelector((state) => state.NextCall.nextCall);
  const lead = useSelector(selectLead(call?.leadId));
  const acquisitionOrder = useSelector(selectAcquisitionOrdersByLead(lead));

  const showStopwatch =
    acquisitionOrder && acquisitionOrder.callStopwatch > 0 ? true : false;
  const { seconds, minutes, isRunning, start, pause, reset } = useStopwatch();

  const color = stopwatchColor(
    minutes,
    seconds,
    isRunning,
    acquisitionOrder?.callStopwatch,
  );

  const startStopwatch = useCallback(() => {
    reset();
    start();
  }, []);

  const handleStopwatch = useCallback(
    (call) => {
      if (!call || call === 'none' || !lead) return reset();
      if (call?.status === 'closed') return pause();
      if (call?.endDateCall) return pause();
      if (call?.startDateCall) return startStopwatch();
      if (!call?.startDateCall) return pause();
    },
    [call, lead],
  );

  useEffect(() => {
    handleStopwatch(call);
  }, [call]);

  if (!(call && showStopwatch)) return null;

  return (
    <div className="mr-4 align-self-center">
      <Timer seconds={seconds} minutes={minutes} color={color} />
    </div>
  );
};

export default CallStopwatch;
