import {
  takeEvery,
  put,
  all,
  call,
  takeLatest,
  takeLeading,
  select,
} from 'redux-saga/effects';
import apiCall from '../../helpers/axiosApiCallWrapper';
import types from './actionTypes';
import * as leadsActions from './actions';
import * as monetizationItemsActions from '../monetizationItems/actions';
import * as nextCallActions from '../nextCall/actions';
import { selectAcquisitionOrderByCampaign } from '../../selectors/acquisitionOrder';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import importLead from '../../helpers/importLeadHelper';
import { MULTI_CAMPAIGN_IMPORT } from '../../config/importLeadTypes';
import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

const leadTransformer = (lead) => {
  const data = lead.data();
  return {
    id: lead.id,
    ...data,
    name: `${data.firstName || ''} ${data.lastName || ''}`,
    ...(data.birthday && {
      birthday: toDateFirebase(lead, data, 'birthday').toDate(),
    }),
    ...(data.createdAt && {
      createdAt: toDateFirebase(lead, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(lead, data, 'updatedAt').toDate(),
    }),
  };
};

function* fetchLeadsSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    const leadsRef = rsf.firestore.createCollectionRefWithFilters(
      'leads',
      companyId,
      startDate,
      endDate,
      filters,
    );

    const leadsSnap = yield call(rsf.firestore.getCollection, leadsRef);
    const leads = leadsSnap.docs.map((lead) => leadTransformer(lead));

    yield put(
      leadsActions.fetchLeadsSuccess(leads, startDate, endDate, filters),
    );
  } catch (error) {
    yield put(leadsActions.fetchLeadsFailure(error));
  }
}

function* fetchSingleLeadSaga({ leadId }) {
  try {
    const leadRef = doc(db, 'leads', leadId);
    const leadDoc = yield call(rsf.firestore.getDocument, leadRef);
    const lead = leadDoc.exists ? leadTransformer(leadDoc) : null;

    yield put(leadsActions.fetchSingleLeadSuccess(lead));
  } catch (error) {
    yield put(leadsActions.fetchSingleLeadFailure(error));
  }
}

function* createLeadSaga({ lead }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    const leadsRef = collection(db, 'leads');

    const acquisitionOrder = yield select(
      selectAcquisitionOrderByCampaign(lead.campaignId),
    );

    const { closure, sendInitialSurvey } = lead;

    delete lead.create;
    delete lead.closure;
    delete lead.sendInitialSurvey;

    const newLead = yield call(rsf.firestore.addDocument, leadsRef, {
      ...lead,
      companyId,
      acquisitionOrderId: acquisitionOrder.id,
      sector: acquisitionOrder.sector || 'finance',
      source: 'CRM',
      qualified: closure === 'no' ? 'new' : 'completed',
      survey: sendInitialSurvey === 'default' ? 'new' : 'notNecessary',
    });

    if (closure !== 'no') yield call(createMonetizationItems, lead, newLead.id);

    yield put(leadsActions.createLeadSuccess());
    toastr.success('Lead created!', '');
  } catch (error) {
    yield put(leadsActions.createLeadFailure(error));
  }
}

function* updateLeadSaga({ lead }) {
  try {
    const leadId = lead.id;
    const leadsRef = doc(db, 'leads', lead.id);
    const acquisitionOrder = yield select(
      selectAcquisitionOrderByCampaign(lead.campaignId),
    );

    delete lead.id;
    delete lead.name;
    delete lead.create;

    yield call(
      rsf.firestore.setDocument,
      leadsRef,
      {
        ...lead,
        ...(acquisitionOrder && {
          acquisitionOrderId: acquisitionOrder.id,
          sector: acquisitionOrder.sector,
        }),
        ...(lead.birthday && {
          birthday: Timestamp.fromDate(new Date(lead.birthday)),
        }),
        ...(lead.createdAt && {
          createdAt: Timestamp.fromDate(new Date(lead.createdAt)),
        }),
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );

    if (
      Array.isArray(lead.monetizationOrders) &&
      lead.monetizationOrders.length > 0
    )
      yield call(createMonetizationItems, lead, leadId);

    yield put(leadsActions.fetchSingleLead(leadId));
    yield put(nextCallActions.setDirtyLeadForm(false));
    yield put(leadsActions.updateLeadSuccess());
    toastr.success('Lead updated!', '');
  } catch (error) {
    yield put(leadsActions.updateLeadFailure(error));
  }
}

function* createMonetizationItems(lead, leadId) {
  return Array.isArray(lead.monetizationOrders)
    ? yield all(
        //Positive closure
        lead.monetizationOrders.map((monetizationOrderId) =>
          put(
            monetizationItemsActions.createMonetizationItem({
              campaignId: lead.campaignId,
              leadId,
              monetizationOrderId,
            }),
          ),
        ),
      )
    : yield put(
        //Negative closure
        monetizationItemsActions.createMonetizationItem({
          campaignId: lead.campaignId,
          negativeOutcome: lead.negativeOutcome,
          leadId,
        }),
      );
}

function* importLeadsSaga({
  importLeadType,
  campaignId,
  startDate,
  endDate,
  costPerLead,
  leads,
}) {
  try {
    const leadsFromCSV = leads.map((lead) => lead.data);
    const importTime = new Date().getTime();
    let leadsToImport = [];

    //Validate CSV
    leadsFromCSV.forEach((lead, index) => {
      if (campaignId === MULTI_CAMPAIGN_IMPORT.value && !lead.campaignId)
        throw new Error(
          `Check CSV at row ${
            index + 2
          }: campaignId is mandatory in 'Multi' import. Please retry!`,
        );

      const leadData = importLead(importLeadType, lead);
      const { error } = leadData;
      if (error)
        throw new Error(
          `Check CSV at row ${index + 2}: ${error}. Please retry!`,
        );
      else leadsToImport.push(leadData);
    });

    const companyId = yield select((state) => state.Dashboard.companyId);
    const rangeLeadsRef = query(
      collection(db, 'leads'),
      where('createdAt', '>=', startDate),
      where('createdAt', '<=', endDate),
      where('companyId', '==', companyId),
    );

    const rangeLeadsSnap = yield call(
      rsf.firestore.getCollection,
      rangeLeadsRef,
    );

    let importLeads = [];

    if (!rangeLeadsSnap.empty) {
      leadsToImport.forEach((leadToImport) => {
        let existingLead = false;
        rangeLeadsSnap.forEach((lead) => {
          const data = lead.data();
          if (data.phone === leadToImport.phone) {
            existingLead = true;
            return;
          }
        });
        !existingLead && importLeads.push(leadToImport);
      });
    } else importLeads = [...leadsToImport];

    const leadsRef = collection(db, 'leads');
    yield all(
      importLeads.map((lead) =>
        call(rsf.firestore.addDocument, leadsRef, {
          ...lead,
          importFrom: importLeadType,
          importTime,
          campaignId: lead.campaignId || campaignId,
          companyId,
          source: lead.source || 'CRM',
          ...(costPerLead > 0 && { cost: costPerLead }),
        }),
      ),
    );

    yield put(leadsActions.importLeadsSuccess());
    toastr.success(`${importLeads.length} Leads imported!`, '');
  } catch (error) {
    yield put(leadsActions.importLeadsFailure(error));
    toastr.error(error.message, 'Error');
  }
}

function* duplicateLeadsSaga({ campaignId, leads }) {
  try {
    if (!Array.isArray(leads)) throw new Error('Leads must be an array!');

    let responses = yield all(
      leads.map((lead) => {
        const { personId } = lead;
        return call(
          apiCall,
          'GET',
          `/createLeadFromPerson/${personId}/${campaignId}?responseType=text&isDuplicated=1`,
        );
      }),
    );
    responses = responses.map((response, index) => ({
      leadId: leads[index].id,
      response,
    }));
    yield put(leadsActions.duplicateLeadsSuccess(responses));
  } catch (error) {
    yield put(leadsActions.duplicateLeadsFailure(error));
    toastr.error(error, 'Error');
  }
}

function* createCallLeadsSaga({ leads }) {
  try {
    if (!Array.isArray(leads)) throw new Error('Leads must be an array!');

    yield all(
      leads.map((lead) => {
        const leadsRef = doc(db, 'leads', lead.id);
        return call(
          rsf.firestore.setDocument,
          leadsRef,
          {
            qualified: 'new',
          },
          { merge: true },
        );
      }),
    );
    yield put(
      leadsActions.createCallLeadsSuccess(
        leads.map((lead) => ({
          leadId: lead.id,
          response: 'Call created!',
        })),
      ),
    );
  } catch (error) {
    yield put(leadsActions.createCallLeadsFailure(error));
    toastr.error(error, 'Error');
  }
}

function* addPushTokenLeadSaga({ leadId, pushToken, os, browser }) {
  try {
    if (!leadId) throw new Error('LeadId is mandatory!');
    if (!pushToken) throw new Error('PushToken is mandatory!');

    yield call(apiCall, 'POST', `/addPushTokenLead/${leadId}`, {
      token: pushToken,
      pushType: 'web',
      os,
      browser,
    });
    yield put(leadsActions.addPushTokenLeadSuccess());
  } catch (error) {
    yield put(leadsActions.addPushTokenLeadFailure(error));
    toastr.error(error, 'Error');
  }
}

function* allLeadsSaga() {
  yield all([
    takeLatest(types.FETCH_LEADS.REQUEST, fetchLeadsSaga),
    takeEvery(types.IMPORT_LEADS.REQUEST, importLeadsSaga),
    takeEvery(types.FETCH_SINGLE_LEAD.REQUEST, fetchSingleLeadSaga),
    takeLeading(types.CREATE_LEAD.REQUEST, createLeadSaga),
    takeLeading(types.UPDATE_LEAD.REQUEST, updateLeadSaga),
    takeLeading(types.DUPLICATE_LEADS.REQUEST, duplicateLeadsSaga),
    takeLeading(types.CREATE_CALL_LEADS.REQUEST, createCallLeadsSaga),
    takeLeading(types.ADD_PUSH_TOKEN_LEAD.REQUEST, addPushTokenLeadSaga),
  ]);
}

export default allLeadsSaga;
