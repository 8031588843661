import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as messageTemplatesActions from '../../store/messageTemplates/actions';
import { useDispatch, useSelector } from 'react-redux';
import FormMessageTemplate from '../../components/MessageTemplates/FormMessageTemplate';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/MessageTemplates/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

const List = (props) => {
  const loading = useSelector((state) => state.MessageTemplate.loading);
  const messageTemplates = useSelector(
    (state) => state.MessageTemplate.messageTemplates,
  );

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(messageTemplatesActions.syncMessageTemplates());
  }, []);

  const openEditModal = (messageTemplate = null) => {
    setSelectedMessageTemplate(messageTemplate);
    setEditModal(true);
  };

  const openViewModal = (messageTemplate) => {
    setSelectedMessageTemplate(messageTemplate);
    setViewModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal),
    [],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Message Templates" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Message Template"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={messageTemplates}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Message Template"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedMessageTemplate} />
          </Modal>
          <Modal
            size="xl"
            title="Message Template"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormMessageTemplate
              closeModal={() => setEditModal(false)}
              create={!selectedMessageTemplate}
              loading={loading}
              messageTemplate={selectedMessageTemplate}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
