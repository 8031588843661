import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as companiesActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

const db = getFirestore(rsf.app);

function* createCompanySaga({ company }) {
  try {
    const companiesRef = collection(db, 'companies');

    yield call(rsf.firestore.addDocument, companiesRef, {
      ...company,
      createdAt: serverTimestamp(),
    });
    yield put(companiesActions.createCompanySuccess(company));
    toastr.success('Company created!', '');
  } catch (error) {
    yield put(companiesActions.createCompanyFailure(error));
  }
}

function* updateCompanySaga({ company }) {
  try {
    const companiesRef = doc(db, 'companies', company.id);
    delete company.id;

    yield call(
      rsf.firestore.setDocument,
      companiesRef,
      {
        ...company,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(companiesActions.updateCompanySuccess(company));
    toastr.success('Company updated!', '');
  } catch (error) {
    yield put(companiesActions.updateCompanyFailure(error));
  }
}

const companyTransformer = (payload) => {
  let companies = [];

  payload.forEach((company) => {
    const data = company.data();
    companies.push({
      id: company.id,
      ...data,
      createdAt: toDateFirebase(company, data).toDate(),
    });
  });

  return companies;
};

function* syncCompaniesSaga() {
  const companiesRef = query(
    collection(db, 'companies'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, companiesRef, {
    successActionCreator: companiesActions.syncCompaniesSuccess,
    failureActionCreator: companiesActions.syncCompaniesFailure,
    transform: (payload) => companyTransformer(payload),
  });

  yield take(types.RESET_COMPANY_STATE);
  yield cancel(task);
}

function* companySaga() {
  yield all([
    takeLatest(types.SYNC_COMPANIES.REQUEST, syncCompaniesSaga),
    takeLeading(types.CREATE_COMPANY.REQUEST, createCompanySaga),
    takeLeading(types.UPDATE_COMPANY.REQUEST, updateCompanySaga),
  ]);
}

export default companySaga;
