import {
  takeEvery,
  takeLeading,
  select,
  put,
  all,
  call,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as monetizationItemsActions from './actions';
import { selectMonetizationOrder } from '../../selectors/monetizationOrder';
import { selectAcquisitionOrderByCampaign } from '../../selectors/acquisitionOrder';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  runTransaction,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase, arrayChunks } from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

export const monetizationItemTransformer = (monetizationItem, data) => {
  return {
    id: monetizationItem.id,
    ...data,
    ...(data.sentAt && {
      sentAt: toDateFirebase(monetizationItem, data, 'sentAt').toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(monetizationItem, data, 'updatedAt').toDate(),
    }),
    ...(data.createdAt && {
      createdAt: toDateFirebase(monetizationItem, data).toDate(),
    }),
  };
};

function* createMonetizationItemSaga({ monetizationItem }) {
  let data = {};

  const user = yield select((state) => state.Auth.admin);
  const acquisitionOrder = yield select(
    selectAcquisitionOrderByCampaign(monetizationItem.campaignId),
  );
  const companyId = yield select((state) => state.Dashboard.companyId);

  try {
    if (monetizationItem.monetizationOrderId) {
      //Positive closure
      const monetizationOrder = yield select(
        selectMonetizationOrder(monetizationItem.monetizationOrderId),
      );
      const status =
        monetizationOrder.sendLeadType === 'webService' ? 'new' : 'closed';

      data = {
        status,
        monetizationOrderId: monetizationOrder.id,
        qualifyCost: monetizationOrder.costPositiveClosure,
        revenue: monetizationOrder.payout,
        sendLeadType: monetizationOrder.sendLeadType,
        webService: monetizationOrder.webService || '',
      };
    } else {
      //Negative closure
      data = {
        status: 'closed',
        negativeOutcome: monetizationItem.negativeOutcome,
        qualifyCost:
          monetizationItem.negativeOutcome === 'fake'
            ? 0
            : acquisitionOrder.costNegativeClosure,
        revenue: 0,
      };
    }

    const monetizationItemsRef = collection(db, 'monetizationItems');

    yield call(rsf.firestore.addDocument, monetizationItemsRef, {
      ...data,
      campaignId: monetizationItem.campaignId,
      leadId: monetizationItem.leadId,
      userId: user.id,
      companyId,
      acquisitionOrderId: acquisitionOrder.id,
      sector: acquisitionOrder.sector || 'finance',
      createdAt: serverTimestamp(),
    });
    yield put(
      monetizationItemsActions.createMonetizationItemSuccess(monetizationItem),
    );
    toastr.success('MonetizationItem created!', '');
  } catch (error) {
    yield put(monetizationItemsActions.createMonetizationItemFailure(error));
  }
}

function* fetchMonetizationItemsSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    const monetizationItemsRef = rsf.firestore.createCollectionRefWithFilters(
      'monetizationItems',
      companyId,
      startDate,
      endDate,
      filters,
    );

    const monetizationItemsSnap = yield call(
      rsf.firestore.getCollection,
      monetizationItemsRef,
    );

    let monetizationItems = [];

    monetizationItemsSnap.forEach((monetizationItem) => {
      const data = monetizationItem.data();

      monetizationItems.push(
        monetizationItemTransformer(monetizationItem, data),
      );
    });

    yield put(
      monetizationItemsActions.fetchMonetizationItemsSuccess(
        monetizationItems,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(monetizationItemsActions.fetchMonetizationItemsFailure(error));
  }
}

function* fetchMonetizationItemsByLeadSaga({ lead }) {
  try {
    const monetizationItemsRef = query(
      collection(db, 'monetizationItems'),
      where('leadId', '==', lead.id),
    );

    const monetizationItemsSnap = yield call(
      rsf.firestore.getCollection,
      monetizationItemsRef,
    );

    let monetizationItems = [];

    monetizationItemsSnap.forEach((monetizationItem) => {
      const data = monetizationItem.data();

      monetizationItems.push(
        monetizationItemTransformer(monetizationItem, data),
      );
    });

    yield put(
      monetizationItemsActions.fetchMonetizationItemsByLeadSuccess(
        monetizationItems,
      ),
    );
  } catch (error) {
    yield put(
      monetizationItemsActions.fetchMonetizationItemsByLeadFailure(error),
    );
  }
}

function* fetchMonetizationItemsByPhoneSaga({ phone }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    const leadsRef = query(
      collection(db, 'leads'),
      where('phone', '==', phone),
      where('companyId', '==', companyId),
    );

    const leadsSnap = yield call(rsf.firestore.getCollection, leadsRef);

    let leadIds = leadsSnap.docs.map((lead) => lead.id);
    leadIds = arrayChunks(leadIds, 10);

    const monetizationItemSnaps = yield all(
      leadIds.map((leadIds) =>
        call(
          rsf.firestore.getCollection,
          query(
            collection(db, 'monetizationItems'),
            where('leadId', 'in', leadIds),
          ),
        ),
      ),
    );

    let monetizationItems = [];

    monetizationItemSnaps.forEach((monetizationItemSnap) => {
      monetizationItemSnap.forEach((monetizationItem) => {
        const data = monetizationItem.data();

        monetizationItems.push({
          id: monetizationItem.id,
          ...data,
          createdAt: toDateFirebase(monetizationItem, data).toDate(),
        });
      });
    });

    yield put(
      monetizationItemsActions.fetchMonetizationItemsByPhoneSuccess(
        monetizationItems,
      ),
    );
  } catch (error) {
    yield put(
      monetizationItemsActions.fetchMonetizationItemsByPhoneFailure(error),
    );
  }
}

function* resendMonetizationItemsSaga({ monetizationItems }) {
  try {
    if (!Array.isArray(monetizationItems))
      throw new Error('MonetizationItems must be an array!');

    let responses = yield all(
      monetizationItems.map(({ id }) => {
        const monetizationItemRef = doc(db, 'monetizationItems', id);
        return call(runTransaction, db, (transaction) => {
          return transaction
            .get(monetizationItemRef)
            .then((monetizationItemSnap) => {
              if (!monetizationItemSnap.exists) {
                return Promise.reject('This monetizationItem does not exist!');
              }
              const { status, sendLeadType, revenue, error } =
                monetizationItemSnap.data();

              if (
                !(
                  status === 'error' &&
                  ['webService', 'googleSpreadsheets'].includes(sendLeadType) &&
                  revenue === 0 &&
                  error
                )
              ) {
                return Promise.reject('This monetizationItem does not resend!');
              }

              transaction.update(monetizationItemRef, {
                status: 'new',
                updatedAt: serverTimestamp(),
              });
              return 'OK - Resend scheduled!';
            })
            .catch((error) => error);
        });
      }),
    );
    responses = responses.map((response, index) => ({
      monetizationItemId: monetizationItems[index].id,
      response,
    }));
    //console.log('responses:', responses);

    yield put(
      monetizationItemsActions.resendMonetizationItemsSuccess(responses),
    );
  } catch (error) {
    yield put(monetizationItemsActions.resendMonetizationItemsFailure(error));
    toastr.error(error, 'Error');
  }
}

function* monetizationItemSaga() {
  yield all([
    takeEvery(
      types.FETCH_MONETIZATION_ITEMS.REQUEST,
      fetchMonetizationItemsSaga,
    ),
    takeEvery(
      types.CREATE_MONETIZATION_ITEM.REQUEST,
      createMonetizationItemSaga,
    ),
    takeEvery(
      types.FETCH_MONETIZATION_ITEMS_BY_LEAD.REQUEST,
      fetchMonetizationItemsByLeadSaga,
    ),
    takeEvery(
      types.FETCH_MONETIZATION_ITEMS_BY_PHONE.REQUEST,
      fetchMonetizationItemsByPhoneSaga,
    ),
    takeLeading(
      types.RESEND_MONETIZATION_ITEMS.REQUEST,
      resendMonetizationItemsSaga,
    ),
  ]);
}

export default monetizationItemSaga;
