export default [
  {
    label: 'Finance',
    value: 'finance',
  },
  {
    label: 'Automotive',
    value: 'automotive',
  },
  {
    label: 'Telco',
    value: 'telco',
  },
  {
    label: 'Energy',
    value: 'energy',
  },
  {
    label: 'Insurance',
    value: 'insurance',
  },
  {
    label: 'Educational',
    value: 'educational',
  },
  {
    label: 'Multi',
    value: 'multi',
  },
  {
    label: 'Live Hunting',
    value: 'liveHunting',
  },
];
