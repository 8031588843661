import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as suppliersActions from '../../store/suppliers/actions';
import { useDispatch, useSelector } from 'react-redux';
import FormSupplier from '../../components/Suppliers/FormSupplier';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Suppliers/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

const List = (props) => {
  const loading = useSelector((state) => state.Supplier.loading);
  const suppliers = useSelector((state) => state.Supplier.suppliers);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(suppliersActions.syncSuppliers());
  }, []);

  const openEditModal = (supplier = null) => {
    setSelectedSupplier(supplier);
    setEditModal(true);
  };

  const openViewModal = (supplier) => {
    setSelectedSupplier(supplier);
    setViewModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal),
    [],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Suppliers" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Supplier"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={suppliers}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Supplier"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedSupplier} />
          </Modal>
          <Modal
            size="xl"
            title="Supplier"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormSupplier
              closeModal={() => setEditModal(false)}
              create={!selectedSupplier}
              loading={loading}
              supplier={selectedSupplier}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
