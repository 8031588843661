import { Timestamp } from 'firebase/firestore';

export const toDateFirebase = (snap, data, key = 'createdAt') => {
  return (!data.createdAt && snap.metadata.hasPendingWrites) ||
    typeof data[key] === 'string'
    ? Timestamp.now()
    : data[key];
};

export const DDMMYYYYToDate = (stringDate, separator = '-') => {
  //27-09-2020
  const day = stringDate.split(separator)[0];
  const month = stringDate.split(separator)[1];
  const year = stringDate.split(separator)[2];

  const date = `${year}-${month}-${day}`;

  return !isNaN(Date.parse(date)) ? new Date(date) : null;
};

export const dateToYYYYMMDD = (date, separator = '-') => {
  //date to yyyy-mm-dd
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();

  return yyyy + separator + mm + separator + dd;
};

export const DDMMYYYYHHmmssToDateTime = (stringDate) => {
  //27-09-2020 00:24:10
  const date = stringDate.split(' ')[0];
  const time = stringDate.split(' ')[1];
  const day = date.split('-')[0];
  const month = date.split('-')[1];
  const year = date.split('-')[2];

  return new Date(`${year}-${month}-${day} ${time}`);
};

export const YYYYMMDDHHmmssToDateTime = (stringDate) => {
  //2020-09-27T00:24:10
  const date = stringDate.split('T')[0];
  const time = stringDate.split('T')[1];
  const day = date.split('-')[2];
  const month = date.split('-')[1];
  const year = date.split('-')[0];

  return new Date(`${year}-${month}-${day} ${time}`);
};

export const dateIsInRange = (date, startDate, endDate) => {
  const today = date.getTime();
  const from =
    typeof myVar === 'string'
      ? new Date(startDate).getTime()
      : startDate.getTime();
  const to =
    typeof myVar === 'string' ? new Date(endDate).getTime() : endDate.getTime();
  return today >= from && today <= to;
};

export const addHours = (date, hours) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
};

export const arrayChunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

export const checkEmptyObject = (obj) => {
  for (let key in obj) {
    if (obj[key] && obj[key].length) return false;
  }
  return true;
};

export const isNullish = (obj) => {
  if (!obj) return true;
  return Object.values(obj).every((value) => !value);
};

// Returns an array of dates between the two dates
export const getDates = (startDate, endDate) => {
  let dates = [],
    currentDate = startDate,
    addDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

// Returns seconds between the two dates
export const getSeconds = (startDate, endDate) =>
  Math.abs((startDate.getTime() - endDate.getTime()) / 1000);

// Returns HH:MM:SS from seconds
export const secondsToHHMMSS = (timeSeconds, fromMinutes = false) => {
  var secNum = parseInt(timeSeconds, 10); // don't forget the second param
  var hours = Math.floor(secNum / 3600);
  var minutes = Math.floor((secNum - hours * 3600) / 60);
  var seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return fromMinutes
    ? minutes + ':' + seconds
    : hours + ':' + minutes + ':' + seconds;
};

export const dateToTimeString = (date) =>
  `${String(date.getHours()).padStart(2, '0')}:${String(
    date.getMinutes(),
  ).padStart(2, '0')}`;

export const selectTranslation = (options, t) =>
  options.map(({ value, label }) => ({ value, label: t(label) }));

export const arrayToSelect = (
  array,
  translate = null,
  capitalizeFirst = true,
) =>
  array.map((value) => {
    let label = capitalizeFirst ? capitalize(value, true) : value;
    label = translate ? translate(label) : label;
    return { value, label };
  });

export const capitalize = ([first, ...rest], lowerRest = false) =>
  first.toUpperCase() +
  (lowerRest ? rest.join('').toLowerCase() : rest.join(''));

export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const linkify = (text) => {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
};

export const normalizePhone = (phoneNumber) => {
  let mobile = phoneNumber.replace(/ /g, ''); // Remove all spaces
  if (mobile.substring(0, 2) === '00') return `+${mobile.substring(2)}`;
  if (mobile[0] !== '+' && mobile.length <= 10) return `+39${mobile}`; //assume that is a italian phone number
  return mobile[0] === '+' ? mobile : `+${mobile}`;
};

export const removeIntPrefix = (phone) => phone.replace(/\+\d{2}/g, '');

export const replaceMacro = (str, macroValues) =>
  str.replace(/{{\w+}}/g, (macros) => {
    const key = macros.replace('{{', '').replace('}}', '');
    return macroValues[key] || '';
  });

export const orderByKey = (objs, key) =>
  objs.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));

export const addOrRemove = (arr, item) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

export const orderMapByKey = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

export const filterObj = (obj, keysToRemove) => {
  const result = { ...obj };
  keysToRemove.forEach((key) => {
    delete result[key];
  });
  return result;
};
