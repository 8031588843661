import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Progress } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  countMakeCallsNow,
  countNewQueuedCalls,
  countMakeRecallsNow,
  countExpiredAppointments,
  nextCallTime,
} from '../../selectors/call';
import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';
import { dateToTimeString } from '../../helpers/sharedFunction';

const NotificationMakeCalls = (props) => {
  const [menu, setMenu] = useState(false);

  const user = useSelector((state) => state.Auth.admin);
  const userId = user.role === roles.OPERATOR ? user.id : null;

  const makeCalls = useSelector(
    countMakeCallsNow(userId, { notArchivedCampaigns: true }),
  );
  const makeNewCalls = useSelector(
    countNewQueuedCalls({ notArchivedCampaigns: true }),
  );
  const makeRecalls = useSelector(
    countMakeRecallsNow({ notArchivedCampaigns: true }),
  );
  const expiredAppointments = useSelector(
    countExpiredAppointments(userId, { notArchivedCampaigns: true }),
  );
  const nextCall = useSelector(
    nextCallTime(userId, { notArchivedCampaigns: true }),
  );

  const makeCallsReport = [
    { label: 'New calls', value: makeNewCalls, color: 'success' },
    { label: 'Make recalls', value: makeRecalls, color: 'warning' },
    {
      label: 'Expired appointments',
      value: expiredAppointments,
      color: 'info',
    },
  ];

  const { t } = useTranslation();

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li">
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          style={{ width: '60px' }}
          tag="button"
          id="page-header-notifications-dropdown">
          <i
            className={`bx bx-phone-call ${
              makeCalls > 0 ? 'bx-tada' : ''
            }`}></i>
          {makeCalls > 0 && (
            <span className="badge badge-danger badge-pill">{makeCalls}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3 pb-0">
            <h4 className="m-0">
              {' '}
              {t('make_calls_number', { countMakeCalls: makeCalls })}{' '}
            </h4>
            {makeCalls === 0 && nextCall && (
              <h5 className="mt-3 mb-0">
                {t('Next call at')}{' '}
                <span className="text-info">{dateToTimeString(nextCall)}</span>
              </h5>
            )}
          </div>

          <div className="table-responsive mt-2">
            <table className="table table-centered table-nowrap mb-2">
              <tbody>
                {makeCallsReport.map((report, index) => (
                  <tr key={'callsReport_' + index}>
                    <td style={{ width: '30%' }}>
                      <p className="mb-0">{t(report.label)}</p>
                    </td>
                    <td style={{ width: '15%' }}>
                      <h5 className="mb-0">{report.value}</h5>
                    </td>
                    <td>
                      <Progress
                        value={(report.value / makeCalls) * 100}
                        color={report.color}
                        striped
                        className="bg-transparent"
                        size="sm"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {!userId && (
            <div className="p-2 border-top">
              <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                to="/calls/list">
                {' '}
                {t('View all')}{' '}
              </Link>
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default NotificationMakeCalls;
