const statsTypes = {
  RESET_CUSTOMER_STATE: 'RESET_CUSTOMER_STATE',
  CREATE_CUSTOMER: {
    REQUEST: 'CREATE_CUSTOMER.REQUEST',
    SUCCESS: 'CREATE_CUSTOMER.SUCCESS',
    FAILURE: 'CREATE_CUSTOMER.FAILURE',
  },
  UPDATE_CUSTOMER: {
    REQUEST: 'UPDATE_CUSTOMER.REQUEST',
    SUCCESS: 'UPDATE_CUSTOMER.SUCCESS',
    FAILURE: 'UPDATE_CUSTOMER.FAILURE',
  },
  SYNC_CUSTOMERS: {
    REQUEST: 'SYNC_CUSTOMERS.REQUEST',
    SUCCESS: 'SYNC_CUSTOMERS.SUCCESS',
    FAILURE: 'SYNC_CUSTOMERS.FAILURE',
  },
};

export default statsTypes;
