import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { selectAudienceBySetup } from '../../selectors/audience';
import { selectCampaignsBySource } from '../../selectors/campaign';
import * as marketingCampaignsActions from '../../store/marketingCampaigns/actions';
import * as campaignsActions from '../../store/campaigns/actions';

import { Form } from '@availity/form';
import { Input, Select, Date, Switch } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { components } from 'react-select';
import { capitalize } from '../../helpers/sharedFunction';

const { Option } = components;

const MessageTemplateSelectOption = (props) => (
  <Option {...props}>
    {props.data.type
      ? `${capitalize(props.data.type)}${
          props.data.linkType ? ' - ' + capitalize(props.data.linkType) : ''
        } - ${props.data.label}`
      : props.data.label}
  </Option>
);

const FormMarketingCampaign = (props) => {
  const editingMarketingCampaign = props.marketingCampaign || {};
  const isEditable = props.create
    ? true
    : editingMarketingCampaign.setup === 'new'
    ? true
    : false;

  const [active, setActive] = useState(
    editingMarketingCampaign.active || false,
  );

  const messageTemplates = props.messageTemplates.map(
    ({ id, name, templateType, linkType }) => ({
      value: id,
      label: name,
      type: templateType,
      linkType: linkType,
    }),
  );

  const audiences = useSelector(selectAudienceBySetup('completed'));
  const campaigns = useSelector(selectCampaignsBySource('marketingAutomation'));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      props.create
        ? marketingCampaignsActions.createMarketingCampaign({
            ...values,
            active,
          })
        : marketingCampaignsActions.updateMarketingCampaign({
            id: editingMarketingCampaign.id,
            ...values,
            active,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingMarketingCampaign, active }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Row>
                <Col md="5">
                  <Input id="name" name="name" />
                </Col>
                <Col md="2">
                  <Date
                    id="personCreatedFrom"
                    name="personCreatedFrom"
                    label="Person Created From"
                    format="DD/MM/YYYY"
                    disabled={!isEditable}
                  />
                </Col>
                <Col md="2">
                  <Date
                    id="personCreatedTo"
                    name="personCreatedTo"
                    label="Person Created To"
                    format="DD/MM/YYYY"
                  />
                </Col>
                <Col md="2">
                  <Switch
                    name="active"
                    label="Active?"
                    defaultChecked={active}
                    onClick={() => setActive(!active)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Select
                      name="audienceId"
                      id="audienceId"
                      label="Audience"
                      isMulti={false}
                      options={audiences.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      isDisabled={!isEditable}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <Select
                    name="campaignId"
                    id="campaignId"
                    label="Campaign"
                    isMulti={false}
                    options={campaigns.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    isDisabled={!isEditable}
                  />
                </Col>
                <Col md="3">
                  <Select
                    name="messageTemplateId"
                    id="messageTemplateId"
                    label="Message"
                    isMulti={false}
                    options={[
                      { value: 'none', label: 'None', type: null },
                      ...messageTemplates,
                    ]}
                    components={{ Option: MessageTemplateSelectOption }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    id="creationDelay"
                    name="creationDelay"
                    label="Creation Delay"
                    min={0}
                    type="number"
                    step="1"
                  />
                  <p className="font-size-12 text-muted">Value in days</p>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Date
                    id="firstSendingDate"
                    name="firstSendingDate"
                    label="First Sending Date"
                    format="DD/MM/YYYY"
                  />
                </Col>
                <Col md="2">
                  <Input
                    id="sendingTimeMessage"
                    name="sendingTimeMessage"
                    label="Sending Time Message"
                    placeholder="00:00"
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormMarketingCampaign;
