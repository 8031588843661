import { createSelector } from 'reselect';
import roles from '../config/roles';

export const selectCompany = (id) =>
  createSelector(
    (state) => state.Company.companies,
    (companies) => {
      if (!id) return null;
      const company = companies.filter((company) => company.id === id);
      return company.length > 0 ? company[0] : null;
    },
  );

export const actualCompany = createSelector(
  (state) => state.Dashboard.companyId,
  (state) => state.Company.companies,
  (companyId, companies) => {
    if (!companyId) return null;
    const company = companies.filter((company) => company.id === companyId);
    return company.length > 0 ? company[0] : null;
  },
);

export const isModuleFacebookActive = createSelector(
  actualCompany,
  (company) => !!company.facebookModule,
);

export const isModuleGoogleActive = createSelector(
  actualCompany,
  (company) => !!company.googleModule,
);

export const isModuleSupplierActive = createSelector(
  actualCompany,
  (company) => !!company.supplierModule,
);

export const actualFacebookAdAccountIds = createSelector(
  actualCompany,
  (company) =>
    !company.facebookAdAccountIds ||
    !Array.isArray(company.facebookAdAccountIds) ||
    company.facebookAdAccountIds.length === 0
      ? null
      : company.facebookAdAccountIds,
);

export const activeCompanies = createSelector(
  (state) => state.Company.companies,
  (companies) => companies.filter((company) => company.active),
);

export const availableCompanies = createSelector(
  (state) => state.Auth.admin,
  activeCompanies,
  (user, companies) => {
    if (user.role === roles.SUPER_ADMIN) return companies;
    return companies.filter((company) => user.companyIds.includes(company.id));
  },
);
