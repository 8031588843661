import React from 'react';
import {
  ViewButton,
  ViewLeadButton,
} from '../Common/DataTable/tableActionButtons';
import { getFilter } from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (openLeadModal, openViewModal) => [
  {
    title: 'Phone',
    name: 'phone',
    formatterComponent: ({ value }) =>
      value ? (value[0] === '+' ? value : `+${value}`) : '',
    filter: (value, filter) =>
      getFilter(
        value ? (value[0] === '+' ? value : `+${value}`) : '',
        filter.value,
      ),
    sorting: 'disabled',
  },
  {
    title: 'Reason',
    name: 'reason',
  },
  {
    title: 'Status',
    name: 'status',
    width: 120,
    formatterComponent: ({ value }) =>
      value === 'DELIVERED' ? (
        <span className="badge badge-success font-size-12">{value}</span>
      ) : (
        <span className="badge badge-danger font-size-12">{value}</span>
      ),
  },
  {
    title: 'Provider',
    name: 'provider',
  },
  {
    title: 'Send At',
    name: 'sendAt',
    width: 150,
    formatterComponent: ({ value, row }) =>
      value || row.createdAt
        ? moment(value || row.createdAt).format('DD/MM/YYYY HH:mm')
        : '',
    filter: (value, filter, row) =>
      getFilter(
        value || row.createdAt
          ? moment(value || row.createdAt).format('DD/MM/YYYY HH:mm')
          : '',
        filter.value,
      ),
    exportValue: ({ value, row }) =>
      value || row.createdAt
        ? moment(value || row.createdAt).format('DD/MM/YYYY HH:mm:ss')
        : '',
    sorting: 'disabled',
  },
  {
    title: 'Created At',
    name: 'createdAt',
    width: 120,
    formatterComponent: ({ value }) => moment(value).fromNow(),
    filter: (value, filter) => getFilter(moment(value).fromNow(), filter.value),
    exportValue: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    title: 'Action',
    name: 'action',
    width: 120,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <ViewLeadButton row={row} onPress={openLeadModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
