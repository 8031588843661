import React, { useEffect } from 'react';

import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';
import { selectMonetizationOrderWithSendType } from '../../selectors/monetizationOrder';
import { useDispatch, useSelector } from 'react-redux';
import { replaceMacro, removeIntPrefix } from '../../helpers/sharedFunction';

const MonetizationOrderLink = ({ name, link, key }) => (
  <tr key={'monetizationOrder_' + key}>
    <td className="d-flex">
      <i className="bx bx-right-arrow-alt align-middle mr-2"></i>
      <h6 className="mb-0">{name}</h6>
    </td>
    <td>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={link}
        className="btn btn-outline-info btn-sm w-xs">
        <i className="bx bx-link-external align-middle mr-2"></i>
        Link
      </a>
    </td>
  </tr>
);

const DataEntryLinks = (props) => {
  const monetizationOrders = useSelector(
    selectMonetizationOrderWithSendType('dataEntry'),
  );

  const selectedMonetizationOrders =
    props.selectedMonetizationOrders || monetizationOrders;

  const dispatch = useDispatch();

  const macros = {
    ...props?.lead,
    phoneNoIntPref: removeIntPrefix(props?.lead.phone),
  };

  useEffect(() => {
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
  }, []);

  return (
    <div className="table-responsive">
      <table className="table mb-2">
        <tbody>
          {selectedMonetizationOrders.map(
            ({ id, name, landingPageLead, landingPageCustomer }) => {
              const link = props?.lead[landingPageLead] || landingPageCustomer;
              return (
                <MonetizationOrderLink
                  key={id}
                  name={name}
                  link={replaceMacro(link, macros)}
                />
              );
            },
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataEntryLinks;
