export default [
  ...(process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? [
        {
          label: '** WS TEST **',
          value: 'test_ws',
        },
      ]
    : []),
  {
    label: 'Pitagora Nuova Cessione',
    value: 'pitagora_nuova_cessione',
  },
  {
    label: 'Pitagora Antetermine',
    value: 'pitagora_antetermine',
  },
  {
    label: 'Pitagora Nuova Cessione - Mktg',
    value: 'pitagora_nuova_cessione_mktg',
  },
  {
    label: 'Pitagora Antetermine - Mktg',
    value: 'pitagora_antetermine_mktg',
  },
  {
    label: 'IBL Banca',
    value: 'ibl_banca',
  },
  {
    label: 'IBL Banca Live Hunting',
    value: 'ibl_banca_hunting',
  },
  {
    label: 'Compass - Generic',
    value: 'compass_generic',
  },
  {
    label: 'Compass - Orfin',
    value: 'compass_orfin',
  },
  {
    label: 'Compass - Recall',
    value: 'compass_recall',
  },
  {
    label: 'Compass Live Hunting - Positiva',
    value: 'compass_hunting_positive',
  },
  {
    label: 'Compass Live Hunting - Negativa',
    value: 'compass_hunting_negative',
  },
  {
    label: 'Optima Italia',
    value: 'optima',
  },
  {
    label: 'Ford Italia',
    value: 'ford',
  },
  {
    label: 'Ford Kuga Fleet',
    value: 'ford_kuga_fleet',
  },
  {
    label: 'Consulting Services',
    value: 'conservices',
  },
  {
    label: 'Dynamica Retail',
    value: 'dynamica_retail',
  },
  {
    label: 'Toyota Edimotive',
    value: 'toyota_edimotive',
  },
  {
    label: 'Toyota IOL',
    value: 'toyota_iol',
  },
  {
    label: 'Multiversity',
    value: 'multiversity',
  },
  {
    label: 'Electric Days 2023',
    value: 'electric_days',
  },
  {
    label: 'Ford - Electric Days 2023',
    value: 'ford_electric_days',
  },
  {
    label: 'Hyundai - Electric Days 2023',
    value: 'hyundai_electric_days',
  },
  {
    label: 'Koelliker - Electric Days 2023',
    value: 'koelliker_electric_days',
  },
];
