import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';

const DatePresetButton = (props) => {
    const disabled =
        props.range === 'thisMonth'
            ? props.loading || moment.utc().startOf('month').isSame(moment(), 'day')
            : props.loading;

    return (
        <Button
            color={props.color}
            className="btn btn-sm waves-effect"
            disabled={disabled}
            onClick={props.onClick}
            style={{ marginRight: '10px' }}>
            {props.title}
        </Button>
    );
};

export default DatePresetButton;
