import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_COMPANY_STATE,
});

export const createCompany = (company) => ({
  type: types.CREATE_COMPANY.REQUEST,
  company,
});

export const createCompanySuccess = (company) => ({
  type: types.CREATE_COMPANY.SUCCESS,
  company,
});

export const createCompanyFailure = (error) => ({
  type: types.CREATE_COMPANY.FAILURE,
  error,
});

export const updateCompany = (company) => ({
  type: types.UPDATE_COMPANY.REQUEST,
  company,
});

export const updateCompanySuccess = () => ({
  type: types.UPDATE_COMPANY.SUCCESS,
});

export const updateCompanyFailure = (error) => ({
  type: types.UPDATE_COMPANY.FAILURE,
  error,
});

export const syncCompanies = () => ({
  type: types.SYNC_COMPANIES.REQUEST,
});

export const syncCompaniesSuccess = (companies) => ({
  type: types.SYNC_COMPANIES.SUCCESS,
  companies,
});

export const syncCompaniesFailure = (error) => ({
  type: types.SYNC_COMPANIES.FAILURE,
  error,
});

export const fetchCompany = (companyId) => ({
  type: types.FETCH_COMPANY.REQUEST,
  companyId,
});

export const fetchCompanySuccess = (company) => ({
  type: types.FETCH_COMPANY.SUCCESS,
  company,
});

export const fetchCompanyFailure = (error) => ({
  type: types.FETCH_COMPANY.FAILURE,
  error,
});
