import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as monetizationItemsActions from '../../store/monetizationItems/actions';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import * as usersActions from '../../store/users/actions';
import * as leadsActions from '../../store/leads/actions';
import { useDispatch, useSelector } from 'react-redux';
import { monetizationItemsWithoutWebServiceProblem } from '../../selectors/monetizationItem';
import { isAdmin as isAdminSelector } from '../../selectors/auth';
import { selectLead } from '../../selectors/lead';
import {
  revenuesMonetizationItems,
  costsMonetizationItems,
  countPositiveMonetizationItems,
  countNegativeMonetizationItems,
  countFakeMonetizationItems,
} from '../../selectors/monetizationItem';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/MonetizationItems/tableColumns';
import {
  CountRowTablePlacement,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import MiniCard from '../../components/Common/MiniCard';
import BatchMonetizationItemBar from '../../components/MonetizationItems/BatchMonetizationItemBar';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

const List = (props) => {
  const isAdmin = useSelector(isAdminSelector);

  const [viewModal, setViewModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [leadModal, setLeadModal] = useState(false);
  const [selectedMonetizationItem, setSelectedMonetizationItem] =
    useState(null);
  const [selectedMonetizationItemIds, setSelectedMonetizationItemIds] =
    useState([]);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const loading = useSelector((state) => state.MonetizationItem.loading);

  const monetizationItems = useSelector(
    (state) => state.MonetizationItem.monetizationItems,
  );
  const monetizationItemsCompleted = useSelector(
    monetizationItemsWithoutWebServiceProblem,
  );
  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const users = useSelector((state) => state.User.users);
  const showSelectedLead = useSelector(selectLead(selectedLead));
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );

  //Metrics
  const revenues = useSelector(revenuesMonetizationItems());
  const costs = useSelector(costsMonetizationItems());
  const countPositives = useSelector(countPositiveMonetizationItems());
  const countNegatives = useSelector(countNegativeMonetizationItems());
  const countFakes = useSelector(countFakeMonetizationItems());

  const startDate = useSelector((state) => state.MonetizationItem.startDate);
  const endDate = useSelector((state) => state.MonetizationItem.endDate);
  const dataFilters = useSelector((state) => state.MonetizationItem.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('04/07/2020', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('day'),
    endDate: momentEndDate || moment.utc(),
    sector: dataFilters?.sector || '',
    campaignIds: dataFilters?.campaignIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, sector, campaignIds }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      monetizationItemsActions.fetchMonetizationItems(
        selectedStartDate,
        selectedEndDate,
        {
          sector,
          campaignIds,
        },
      ),
    );
    setSelectedFilters({ startDate, endDate, sector, campaignIds });
  });

  useEffect(() => {
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
    dispatch(usersActions.syncUsers());
  }, []);

  useEffect(() => {
    loading && setSelectedMonetizationItemIds([]);
  }, [loading]);

  const openLeadModal = (monetizationItem) => {
    setSelectedLead(monetizationItem.leadId);
    dispatch(leadsActions.fetchSingleLead(monetizationItem.leadId));
    setLeadModal(true);
  };

  const openViewModal = (monetizationItem) => {
    setSelectedMonetizationItem(monetizationItem);
    setViewModal(true);
  };

  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      createTableColumns(
        openLeadModal,
        openViewModal,
        monetizationOrders,
        acquisitionOrders,
        users,
        showSensitiveData,
        t,
      ),
    [monetizationOrders, acquisitionOrders, users, showSensitiveData, t],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const metrics = [
    {
      title: t('Revenues'),
      text: `${isFinite(revenues) ? revenues.toFixed(2) : 0} €`,
      color: 'dark',
      icon: 'fas fa-laugh-wink',
      isSensitiveData: true,
    },
    {
      title: t('Qualify costs'),
      text: `${isFinite(costs) ? costs.toFixed(2) : 0} €`,
      color: 'danger',
      icon: 'fas fa-sad-tear',
      isSensitiveData: true,
    },
    {
      title: t('Positives'),
      text: countPositives,
      color: 'success',
      icon: 'fas fa-grin-hearts',
    },
    {
      title: t('Negatives'),
      text: countNegatives,
      color: 'danger',
      icon: 'fas fa-sad-tear',
    },
    {
      title: t('Fakes'),
      text: countFakes,
      color: 'warning',
      icon: 'fas fa-sad-cry',
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Monetization Items"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showFilters
            initialFilters={selectedFilters}
          />
          <Row>
            {metrics.map((metric, index) => (
              <Col key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={monetizationItems}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    selection={
                      isAdmin ? selectedMonetizationItemIds : undefined
                    }
                    onSelectionChange={
                      isAdmin ? setSelectedMonetizationItemIds : undefined
                    }
                    nonSelectableIds={monetizationItemsCompleted.map(
                      ({ id }) => id,
                    )}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Lead"
            isOpen={leadModal}
            toggle={() => setLeadModal(!leadModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={showSelectedLead}
              icon="bx bx-user-pin"
            />
          </Modal>
          <Modal
            size="lg"
            title="Monetization Item"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedMonetizationItem}
              icon="bx bx-paper-plane"
            />
          </Modal>
          <BatchMonetizationItemBar
            monetizationItems={monetizationItems}
            selectedMonetizationItemIds={selectedMonetizationItemIds}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
