import { Input } from '@availity/form';
import { FormGroup, InputGroup } from 'reactstrap';
import Label from './Label';

const InputForm = ({
  readOnly,
  style,
  children,
  addonType,
  subText,
  ...restProps
}) => {
  const disabledStyle = readOnly ? { cursor: 'not-allowed' } : {};
  return (
    <FormGroup>
      <Label {...restProps} />
      <InputGroup className="flex-nowrap">
        {addonType === 'prepend' && children}
        <Input
          {...restProps}
          disabled={readOnly}
          style={{ ...style, ...disabledStyle, border: '1px solid #555' }}
        />
        {(!addonType || addonType === 'append') && children}
      </InputGroup>
      {subText && <small className="font-size-12 text-muted">{subText}</small>}
    </FormGroup>
  );
};

export default InputForm;
