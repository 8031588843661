import React, { useEffect } from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import '@availity/yup';
import { withRouter, Link } from 'react-router-dom';
import * as authActions from '../../store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';
import heroImg from '../../assets/images/hero.webp';
import logo from '../../assets/images/logo-sm-light.png';

const Register = (props) => {
  const error = useSelector((state) => state.Auth.error);
  const success = useSelector((state) => state.Auth.success);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetMessages());
  }, []);

  const handleValidSubmit = ({ email, password, displayName }) => {
    dispatch(authActions.registerWithEmail(email, password, displayName));
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome</h5>
                        <p>Enter the magical world of Chiedimi</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-center">
                      <img src={heroImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      initialValues={{
                        email: '',
                        displayName: '',
                        password: '',
                      }}
                      onSubmit={handleValidSubmit}
                      validationSchema={yup.object().shape({
                        email: yup.string().email().required(),
                        displayName: yup.string().required().min(5),
                        password: yup.string().required(),
                      })}>
                      {props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}
                      {error !== '' && <Alert color="danger">{error}</Alert>}
                      {success !== '' && (
                        <Alert color="success">{success}</Alert>
                      )}
                      <div className="form-group">
                        <Field
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          name="displayName"
                          label="Display Name"
                          type="text"
                          placeholder="Enter display name"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          name="password"
                          label="Password"
                          type="password"
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit">
                          Register
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Chiedimi{' '}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {' '}
                    Login
                  </Link>{' '}
                </p>
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);
