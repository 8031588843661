import React, { useState, useEffect } from 'react';
import { CSVReader } from 'react-papaparse';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { Form, Input } from '@availity/form';
import Select from '@availity/select';
import Date from '@availity/date';
import '@availity/yup';
import * as yup from 'yup';

import * as campaignsActions from '../../store/campaigns/actions';
import { selectCampaignsWithActiveAcquisitionOrder } from '../../selectors/campaign';
import { useSelector, useDispatch } from 'react-redux';

import {
  IMPORT_LEAD_TYPES,
  MULTI_CAMPAIGN_IMPORT,
} from '../../config/importLeadTypes';
import { useTranslation } from 'react-i18next';
import { Dots } from 'react-activity';
import toastr from 'toastr';

const buttonRef = React.createRef();
const config = {
  header: true,
  skipEmptyLines: true,
};

const ImportLeads = (props) => {
  const [campaignId, setCampaignId] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [importLeadType, setImportLeadType] = useState(null);
  const [costPerLead, setCostPerLead] = useState(0);

  const [resetCSVReader, setResetCSVReader] = useState(true);

  const companyId = useSelector((state) => state.Dashboard.companyId);
  const campaigns = useSelector(selectCampaignsWithActiveAcquisitionOrder);
  const loading = useSelector((state) => state.Leads.loading);

  const campaignForSelect = Array.isArray(campaigns)
    ? [
        {
          label: MULTI_CAMPAIGN_IMPORT.name,
          value: MULTI_CAMPAIGN_IMPORT.value,
          source: '',
        },
        ...campaigns.map((campaign) => ({
          label: campaign.name,
          value: campaign.id,
          source: campaign.source,
        })),
      ]
    : [];

  const importLeadSchema = IMPORT_LEAD_TYPES.filter(
    ({ companyIds, active }) => companyIds.includes(companyId) && active,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    console.log('handleOnFileLoad: ---------------------------');
    console.log(data);
    console.log('---------------------------');
    props.onFileLoad(
      importLeadType,
      campaignId,
      startDate,
      endDate,
      costPerLead,
      data,
    );
    setResetCSVReader(!resetCSVReader);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    toastr.error(err, 'Error');
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  console.log(importLeadType, campaignId, startDate, endDate, costPerLead);

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        {loading ? (
          <Dots />
        ) : (
          <Form
            initialValues={{
              importLeadType,
              campaignId,
              startDate,
              endDate,
              costPerLead,
            }}
            validationSchema={yup.object().shape({
              campaignId: yup.string().required(),
              startDate: yup.date().required(),
              endDate: yup.date().required(),
              importType: yup.string().required(),
              costPerLead: yup.number().required(),
            })}>
            {({ values }) => (
              <>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label htmlFor="campaignId">{t('Campaign')}:</Label>
                      <Select
                        name="campaignId"
                        id="campaignId"
                        isMulti={false}
                        onChange={(campaign) => setCampaignId(campaign.value)}
                        value={campaignId}
                        raw
                        options={campaignForSelect}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label htmlFor="startDate">
                        {`${t('Compare leads entered')} - ${t('Start Date')}`}:
                      </Label>
                      <Date
                        id="startDate"
                        name="startDate"
                        format="DD/MM/YYYY"
                        value={startDate}
                        onChange={(value) => setStartDate(value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label htmlFor="endDate">
                        {`${t('Compare leads entered')} - ${t('End Date')}`}:
                      </Label>
                      <Date
                        id="endDate"
                        name="endDate"
                        format="DD/MM/YYYY"
                        value={endDate}
                        onChange={(value) => setEndDate(value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="importLeadType">
                        {t('Import type')}:
                      </Label>
                      <Select
                        name="importLeadType"
                        id="importLeadType"
                        isMulti={false}
                        onChange={(value) => setImportLeadType(value)}
                        value={importLeadType}
                        options={importLeadSchema}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <Collapse
                      isOpen={
                        values.campaignId && values.campaignId.source === 'csv'
                      }>
                      <FormGroup>
                        <Label htmlFor="costPerLead">Cost Per Lead</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            €
                          </InputGroupAddon>
                          <Input
                            id="costPerLead"
                            name="costPerLead"
                            value={costPerLead}
                            onChange={(e) =>
                              setCostPerLead(parseFloat(e.target.value))
                            }
                            min={0}
                            type="number"
                            step="0.01"
                          />
                        </InputGroup>
                        <p className="font-size-12 text-muted">
                          {t("Enter zero if you don't know it")}
                        </p>
                      </FormGroup>
                    </Collapse>
                  </Col>
                </Row>
                <Collapse
                  isOpen={
                    !!(
                      campaignId &&
                      startDate !== '' &&
                      endDate !== '' &&
                      importLeadType &&
                      costPerLead >= 0
                    )
                  }>
                  <Row>
                    <Col md="12">
                      <h4 className="text-center">
                        {t(
                          'Before import a CSV file check "campaign ID" and "import type" above, please!',
                        )}
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        isReset={resetCSVReader}
                        config={config}
                        onRemoveFile={handleOnRemoveFile}>
                        {({ file }) => (
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <button
                              type="button"
                              onClick={handleOpenDialog}
                              className="btn btn-primary waves-effect waves-light w-sm">
                              <i className="mdi mdi-upload font-size-18 d-block"></i>
                              {t('Browse file')}
                            </button>
                            <div
                              style={{
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: '#ccc',
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: '60%',
                              }}>
                              {file && file.name}
                            </div>
                            <button
                              type="button"
                              onClick={handleRemoveFile}
                              className="btn btn-danger waves-effect waves-light w-sm">
                              <i className="mdi mdi-trash-can font-size-18 d-block"></i>
                              {t('Remove')}
                            </button>
                          </div>
                        )}
                      </CSVReader>
                    </Col>
                  </Row>
                </Collapse>
              </>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

export default ImportLeads;
