import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as leadFormsActions from '../../store/leadForms/actions';
import * as leadFieldsActions from '../../store/leadFields/actions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Common/Modal/Modal';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/LeadForms/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import FormLeadForm from '../../components/LeadForms/FormLeadForm';

const LeadForms = (props) => {
  const loadingLeadForm = useSelector((state) => state.LeadForm.loading);
  const loadingLeadField = useSelector((state) => state.LeadField.loading);
  const loading = loadingLeadForm || loadingLeadField;

  const leadForms = useSelector((state) => state.LeadForm.leadForms);
  const leadFields = useSelector((state) => state.LeadField.leadFields);

  const [editModal, setEditModal] = useState(false);
  const [selectedLeadForm, setSelectedLeadForm] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leadFormsActions.syncLeadForms());
    dispatch(leadFieldsActions.syncLeadFields());
  }, []);

  const openEditModal = (leadForm = null) => {
    setSelectedLeadForm(leadForm);
    setEditModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openEditModal, leadFields),
    [leadFields],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Lead Forms" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Lead Form"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={leadForms}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Lead Form"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormLeadForm
              closeModal={() => setEditModal(false)}
              create={!selectedLeadForm}
              loading={loading}
              leadForm={selectedLeadForm}
              leadForms={leadForms}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LeadForms;
