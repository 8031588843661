import { SweetAlertOptionalPropsWithDefaults } from "./types";

export const SWEET_ALERT_DEFAULT_PROPS: SweetAlertOptionalPropsWithDefaults = {
  allowEscape         : true,
  closeOnClickOutside : true,
  inputType           : "text",
  customClass         : "",
  validationMsg       : null,
  validationRegex     : null,
  hideOverlay         : false,
  required            : true,
  disabled            : false,
  focusConfirmBtn     : true,
  focusCancelBtn      : false,
  showCloseButton     : false,
  confirmBtnBsStyle   : "primary",
  cancelBtnBsStyle    : "link",
  beforeMount         : () => {},
  afterMount          : () => {},
  beforeUpdate        : null,
  afterUpdate         : () => {},
  beforeUnmount       : () => {},
  style               : {},
  closeBtnStyle       : {},
  timeout             : 0,
  openAnim            : { name: "showSweetAlert", duration: 300 },
  closeAnim           : false,
  reverseButtons      : false,
  dependencies        : [],
};
