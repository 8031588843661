import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import logo from '../../../assets/images/logo-sm-light.png';

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';

const ProfileMenu = (props) => {

   // Declare a new state variable, which we'll call "menu"
   const [menu, setMenu] = useState(false);
  
   const admin = useSelector(state => state.Auth.admin);

   const {t, i18n} = useTranslation();

  return (
    <React.Fragment>
                <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="rounded-circle header-profile-user" src={admin.photoURL || logo} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{admin.displayName}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <Link to="/logout" className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{t('Logout')}</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
  );
}

export default withRouter(ProfileMenu);

