import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as dailyInsightsActions from '../../store/campaigns/dailyInsights/actions';
import * as campaignsActions from '../../store/campaigns/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  totalRevenues,
  totalAdvCosts,
  totalCosts,
  totalMargin,
  totalLeads,
  totalPositiveLeads,
} from '../../selectors/campaign';
import { selectSupplierByType } from '../../selectors/supplier';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import MiniCard from '../../components/Common/MiniCard';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Campaigns/DailyInsights/tableColumns';
import {
  CountRowTablePlacement,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import moment from 'moment';
import supplierTypes from '../../config/supplierTypes';
import { useTranslation } from 'react-i18next';

const DailyInsights = (props) => {
  const dailyInsights = useSelector(
    (state) => state.DailyCampaignInsights.insights,
  );
  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const publishers = useSelector(selectSupplierByType(supplierTypes.PUBLISHER));
  const loading = useSelector((state) => state.DailyCampaignInsights.loading);

  const [selectedInsight, setSelectedInsight] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const leads = useSelector(totalLeads);
  const positiveLeads = useSelector(totalPositiveLeads);
  const percentagePositiveLeads = (positiveLeads / leads) * 100;

  const revenues = useSelector(totalRevenues);
  const advCosts = useSelector(totalAdvCosts());
  const costs = useSelector(totalCosts());
  const percentageCosts = (costs / revenues) * 100;

  const margin = useSelector(totalMargin());
  const percentageMargin = (margin / revenues) * 100;

  const startDate = useSelector(
    (state) => state.DailyCampaignInsights.startDate,
  );
  const endDate = useSelector((state) => state.DailyCampaignInsights.endDate);
  const dataFilters = useSelector(
    (state) => state.DailyCampaignInsights.filters,
  );
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('04/07/2020', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    sector: dataFilters?.sector || '',
    campaignIds: dataFilters?.campaignIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, sector, campaignIds }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      dailyInsightsActions.fetchDailyInsights(
        selectedStartDate,
        selectedEndDate,
        {
          sector,
          campaignIds,
        },
      ),
    );
    setSelectedFilters({ startDate, endDate, sector, campaignIds });
  });

  useEffect(() => {
    dailyInsights.length === 0 && refresh(selectedFilters);
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const openViewModal = (insight) => {
    setSelectedInsight(insight);
    setViewModal(true);
  };

  const { t } = useTranslation();

  const metrics = [
    {
      title: 'Leads',
      text: leads,
      color: 'info',
      icon: 'bx bx-user-pin',
    },
    {
      title: t('Positives'),
      text: positiveLeads,
      color: 'success',
      icon: 'bx bx-user-pin',
      percentage: `${
        isFinite(percentagePositiveLeads)
          ? percentagePositiveLeads.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Revenues'),
      text: `${isFinite(revenues) ? revenues.toFixed(0) : 0} €`,
      color: 'dark',
      icon: 'fas fa-laugh-wink',
      isSensitiveData: true,
    },
    {
      title: t('Adv Costs'),
      text: `${isFinite(advCosts) ? advCosts.toFixed(0) : 0} €`,
      color: 'danger',
      icon: 'fas fa-sad-tear',
      isSensitiveData: true,
    },
    {
      title: t('Total Costs'),
      text: `${isFinite(costs) ? costs.toFixed(0) : 0} €`,
      color: 'danger',
      icon: 'fas fa-sad-tear',
      percentage: `${
        isFinite(percentageCosts) ? percentageCosts.toFixed(0) : 0
      }%`,
      isSensitiveData: true,
    },
    {
      title: t('Margin'),
      text: `${isFinite(margin) ? margin.toFixed(0) : 0} €`,
      color: 'success',
      icon: 'fas fa-grin-hearts',
      percentage: `${
        isFinite(percentageMargin) ? percentageMargin.toFixed(0) : 0
      }%`,
      isSensitiveData: true,
    },
  ];

  const columns = useMemo(
    () => createTableColumns(campaigns, publishers, openViewModal),
    [campaigns, publishers],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Daily Campaign Insights"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showFilters
            initialFilters={selectedFilters}
          />
          <Row>
            {metrics.map((metric, index) => (
              <Col md="2" key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={dailyInsights}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Call"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedInsight}
              icon="bx bx-message-square-detail"
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DailyInsights;
