import React, { useState, useEffect } from 'react';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { useTranslation } from 'react-i18next';

const TableRowCounter = (props) => {
  const [container, setContainer] = useState(null);

  useEffect(
    () => setContainer(document.getElementById('row-counter-table')),
    [],
  );

  const { t } = useTranslation();

  if (!container) return null;

  return (
    <Plugin>
      <Template name="root">
        {() => (
          <TemplateConnector>
            {({ rows, totalCount }) => {
              container.innerHTML = `<span>
                  ${t('Rows')}: <strong>${totalCount}</strong>
                </span>`;

              return <TemplatePlaceholder />;
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  );
};

export default TableRowCounter;
