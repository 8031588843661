const statsTypes = {
  RESET_DASHBOARD_STATE: 'RESET_DASHBOARD_STATE',
  SET_DASHBOARD_FILTERS: {
    REQUEST: 'SET_DASHBOARD_FILTERS.REQUEST',
    SUCCESS: 'SET_DASHBOARD_FILTERS.SUCCESS',
    FAILURE: 'SET_DASHBOARD_FILTERS.FAILURE',
  },
  SET_COMPANY: {
    REQUEST: 'SET_COMPANY.REQUEST',
    SUCCESS: 'SET_COMPANY.SUCCESS',
    FAILURE: 'SET_COMPANY.FAILURE',
  },
  SET_EXCHANGE_QUEUES_MODAL: 'SET_EXCHANGE_QUEUES_MODAL',
};

export default statsTypes;
