import React, { useState, useCallback } from 'react';
import { Row, Col } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import * as dashboardActions from '../../store/dashboard/actions';

// Pages Components
import WelcomeBoxWide from '../../components/Dashboard/WelcomeBoxWide';
import AppointmentsBox from '../../components/Dashboard/AppointmentsBox';
import MonetizationItemsInsights from '../../components/Dashboard/MonetizationItemsInsights';
import CallMetrics from '../../components/Dashboard/CallMetrics';
import CallsGraph from '../../components/Dashboard/CallsGraph';
import PositivesHourlyBar from '../../components/Dashboard/PositivesHourlyBar';
import PositivesWeeklyDayBar from '../../components/Dashboard/PositivesWeeklyDayBar';
import PhoneExchangeQueuesBox from '../../components/Dashboard/PhoneExchangeQueuesBox';

import Modal from '../../components/Common/Modal/Modal';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const OperatorDashboard = (props) => {
  //const isManager = useSelector(isManagerSelector);
  //const isTeamLeader = useSelector(isTeamLeaderSelector);

  const startDate = useSelector((state) => state.Dashboard.startDate);
  const endDate = useSelector((state) => state.Dashboard.endDate);
  const filters = useSelector((state) => state.Dashboard.filters);
  const companyId = useSelector((state) => state.Dashboard.companyId);
  const exchangeQueuesModal = useSelector(
    (state) => state.Dashboard.exchangeQueuesModal,
  );
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('04/07/2020', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('day'),
    endDate: momentEndDate || moment.utc(),
    sector: filters?.sector || '',
    campaignIds: filters?.campaignIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, sector, campaignIds }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      dashboardActions.setFilters(selectedStartDate, selectedEndDate, {
        sector,
        campaignIds,
      }),
    );
    setSelectedFilters({ startDate, endDate, sector, campaignIds });
  }, []);

  const openExchangeQueuesModal = () => {
    dispatch(dashboardActions.setExchangeQueuesModal('show'));
  };

  const toggleExchangeQueuesModal = () => {
    exchangeQueuesModal === 'show' &&
      dispatch(dashboardActions.setExchangeQueuesModal('shown'));
  };

  const { t } = useTranslation();

  return (
    <>
      {exchangeQueuesModal !== 'unnecessary' ? (
        <Breadcrumbs
          title={t('Dashboard')}
          onRefresh={() => refresh(selectedFilters)}
          onClickButtons={[openExchangeQueuesModal]}
          titleButtons={[t('Manage exchange queues')]}
          iconButtons={['bx bx-add-to-queue']}
          loading={false}
        />
      ) : (
        <Breadcrumbs
          title={t('Dashboard')}
          onRefresh={() => refresh(selectedFilters)}
          loading={false}
        />
      )}
      <Row>
        <Col md="9">
          <WelcomeBoxWide />
        </Col>
        <Col md="3">
          <AppointmentsBox />
        </Col>
      </Row>

      <DateRangeFilter
        onUpdate={refresh}
        minDate={minDate}
        maxDate={maxDate}
        startDate={selectedFilters.startDate}
        endDate={selectedFilters.endDate}
        loading={false}
        showFilters
        initialFilters={selectedFilters}
      />

      <Row>
        <CallMetrics filters={selectedFilters} />
      </Row>
      <Row>
        <Col md="5">
          <MonetizationItemsInsights />
        </Col>
        <Col md="7">
          <CallsGraph dateRange={selectedFilters} />
          <Row>
            <Col md="6">
              <PositivesHourlyBar />
            </Col>
            <Col md="6">
              <PositivesWeeklyDayBar />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        size="xl"
        title={t('Update Phone Exchange Queues')}
        isOpen={exchangeQueuesModal === 'show'}
        toggle={toggleExchangeQueuesModal}
        showClose
        scrollable={false}>
        <PhoneExchangeQueuesBox companyId={companyId} />
      </Modal>
    </>
  );
};

export default OperatorDashboard;
