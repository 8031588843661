import React, { useMemo } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Form } from '@availity/form';
import { Input } from '../../lib/form-field';
import * as surveysActions from '../../store/surveys/actions';
import { useDispatch } from 'react-redux';

import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { filterObj, arrayChunks } from '../../helpers/sharedFunction';

const HIDDEN_FIELDS = [
  'id',
  'leadId',
  'providerId',
  'companyId',
  'provider',
  'surveyUrl',
  'surveyFormId',
  'deviceType',
  'ipAddress',
  'completed',
  'devicePlatform',
  'createdAt',
  'updatedAt',
];

const FormSurvey = ({ survey, create, closeModal }) => {
  const surveyFields = useMemo(
    () => Object.keys(filterObj(survey, HIDDEN_FIELDS)).sort(),
    [survey],
  );

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      surveysActions.updateSurvey({
        id: survey.id,
        ...values,
      }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form initialValues={survey} onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              {arrayChunks(surveyFields, 3).map((row, index) => (
                <Row key={`row_survey_${index}`}>
                  {row.map((field, index) => (
                    <Col md="4" key={`col_survey_${index}`}>
                      <Input type="text" name={field} id={field} />
                    </Col>
                  ))}
                </Row>
              ))}
              <div className="modal-footer">
                <SubmitButton disabled={!isValid} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormSurvey;
