import React from 'react';
import { Spinner } from 'reactstrap';

const Loader = ({ size, top }) => (
  <div
    className="position-absolute w-100 h-100 top-0 start-0"
    style={{
      backgroundColor: 'rgba(0,0,0,0.1)',
      zIndex: 99999,
    }}>
    <div className="w-100 d-flex justify-content-center align-items-center">
      <Spinner
        color="primary"
        animation="border"
        className="d-flex mx-auto position-absolute"
        style={{
          height: `${size}rem`,
          width: `${size}rem`,
          marginTop: top,
        }}
      />
    </div>
  </div>
);

export default Loader;
