import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Table } from 'reactstrap';

import * as campaignsActions from '../../store/campaigns/actions';
import * as leadsActions from '../../store/leads/actions';
import { selectLeadByQualified } from '../../selectors/lead';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Dots } from 'react-activity';

const CreateCallLeads = ({ leads }) => {
  const [sent, setSent] = useState(false);

  const leadsNotNecessary = useSelector(selectLeadByQualified('notNecessary'));
  const includeLeads = leadsNotNecessary.map((lead) => lead.id);

  leads = leads.filter((lead) => includeLeads.includes(lead.id));

  const loading = useSelector((state) => state.Leads.loading);
  const logs = useSelector((state) => state.Leads.logs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const handleCreateCallLeads = () => {
    dispatch(leadsActions.createCallLeads(leads));
    setSent(true);
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <Row className="my-2">
          <Col md="12">
            <h4 className="text-center">
              {t('create_call_leads', { countLeads: leads.length })}
            </h4>
          </Col>
        </Row>
        <Row className="my-2">
          <Col md="12" className="text-center">
            <Button
              color="info"
              outline
              className="waves-effect waves-light"
              onClick={handleCreateCallLeads}
              disabled={!leads.length || sent}>
              <i className="bx bx-phone-call font-size-20 align-middle mr-2"></i>
              {t('Create Call Leads')}
            </Button>
          </Col>
        </Row>
        {!!(sent && loading) && <Dots />}
        {!!(sent && logs.length) && (
          <Row className="my-2">
            <Col md="12">
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Lead ID</th>
                      <th>Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((log, index) => (
                      <tr key={log.leadId}>
                        <th scope="row">{index}</th>
                        <td>{log.leadId}</td>
                        <td>{log.response}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default CreateCallLeads;
