import React, { useState } from 'react';
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
} from 'reactstrap';
import Modal from '../Common/Modal/Modal';
import DuplicateLeads from './DuplicateLeads';
import CreateCallLeads from './CreateCallLeads';

import { useTranslation } from 'react-i18next';

const BatchLeadBar = ({ leads, selectedLeadIds }) => {
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [createCallModal, setCreateCallModal] = useState(false);

  leads = leads.filter(({ id }) => selectedLeadIds.includes(id));
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`fixed-bottom ${
          selectedLeadIds.length ? 'visible' : 'invisible'
        }`}>
        <Navbar style={{ backgroundColor: '#2a3042' }} dark expand="md">
          <Container style={{ justifyContent: 'flex-start' }}>
            <NavbarBrand>{t('Manage')}:</NavbarBrand>
            <Nav pills>
              <NavItem style={{ marginRight: '15px' }}>
                <Button
                  color="warning"
                  outline
                  className="waves-effect waves-light"
                  onClick={() => setDuplicateModal(true)}>
                  <i className="bx bx-copy-alt font-size-20 align-middle mr-2"></i>
                  {t('Duplicate Leads')}
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color="info"
                  outline
                  className="waves-effect waves-light"
                  onClick={() => setCreateCallModal(true)}>
                  <i className="bx bx-phone-call font-size-20 align-middle mr-2"></i>
                  {t('Create Call')}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>
      </div>
      <Modal
        size="xl"
        title="Duplicate Leads"
        isOpen={duplicateModal}
        toggle={() => setDuplicateModal(!duplicateModal)}
        showClose
        scrollable={false}>
        <DuplicateLeads leads={leads} />
      </Modal>
      <Modal
        size="xl"
        title="Create Call Leads"
        isOpen={createCallModal}
        toggle={() => setCreateCallModal(!createCallModal)}
        showClose
        scrollable={false}>
        <CreateCallLeads leads={leads} />
      </Modal>
    </>
  );
};

export default BatchLeadBar;
