import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist-indexeddb-storage';
import createSagaMiddleware from 'redux-saga';
import Reactotron from 'reactotron-react-js';

import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMonitor = Reactotron.createSagaMonitor();
const storageName =
  process.env.REACT_APP_ENVIRONMENT === 'prod'
    ? 'chiedimi-DB-prod'
    : 'chiedimi-DB-dev';

const persistConfig = {
  key: 'root',
  storage: storage(storageName),
};

const pReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  pReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    Reactotron.createEnhancer(),
  ),
);

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export { persistor, store };
