export default [
  {
    label: 'Data Entry',
    value: 'dataEntry',
  },
  {
    label: 'Web Service',
    value: 'webService',
  },
  {
    label: 'Manual Export',
    value: 'manualExport',
  },
  {
    label: 'Tracking Pixel',
    value: 'trackingPixel',
  },
  {
    label: 'Google Spreadsheets',
    value: 'googleSpreadsheets',
  },
];

export const COMPASS_FA3_LINK_PREFIX = 'https://www.compass.it/fa3/?';
export const COMPASS_REDIRECT_LINK =
  'https://cdn.chiedimi.it/adv/compass-token-redirect.html?codiceFiscale={{fiscalCode}}&cap={{zipCode}}&nome={{firstName}}&cognome={{lastName}}&telefono={{phoneNoIntPref}}&email={{email}}&callbackUrl=';
