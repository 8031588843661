import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as authActions from '../../store/auth/actions';
import { withRouter } from 'react-router-dom';
import { Bounce } from 'react-activity';
import CenterBox from '../../components/Common/CenterBox';
import colors from '../../components/CommonStyle/colors';

const Logout = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.logout(props.history));
  });

  return (
    <CenterBox height={500}>
      <Bounce color={colors.info} size={30} />
    </CenterBox>
  );
};

export default withRouter(Logout);
