import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import allowedRoles from '../../config/assignAllowedRoles';
import types from './actionTypes';
import * as usersActions from './actions';
import { selectUser } from '../../selectors/user';
import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

const db = getFirestore(rsf.app);

function* createUserSaga({ user }) {
  try {
    const db = getFirestore(rsf.app);
    const usersRef = collection(db, 'users');
    yield call(rsf.firestore.addDocument, usersRef, {
      ...user,
      createdAt: serverTimestamp(),
    });
    yield put(usersActions.createUserSuccess(user));
    toastr.success('User created!', '');
  } catch (error) {
    yield put(usersActions.createUserFailure(error));
  }
}

function* updateUserSaga({ user }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    const selectedUser = yield select(selectUser(user.id));

    const acquisitionOrderIds =
      Array.isArray(user.acquisitionOrderIds) && user.acquisitionOrderIds.length
        ? {
            ...selectedUser.acquisitionOrderIds,
            [`${companyId}`]: user.acquisitionOrderIds,
          }
        : { ...selectedUser.acquisitionOrderIds, [`${companyId}`]: null };

    const usersRef = doc(db, 'users', user.id);
    delete user.id;

    yield call(
      rsf.firestore.setDocument,
      usersRef,
      {
        ...user,
        acquisitionOrderIds,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(usersActions.updateUserSuccess(user));
    toastr.success('User updated!', '');
  } catch (error) {
    yield put(usersActions.updateUserFailure(error));
  }
}

const userTransformer = (payload, params) => {
  const admin = params.user;
  const roles = allowedRoles[admin.role];
  let users = [];

  payload.forEach((user) => {
    const data = user.data();
    if (roles.includes(data.role) || user.id === admin.id) {
      users.push({
        id: user.id,
        ...data,
        ...(data.createdAt && {
          createdAt: toDateFirebase(user, data).toDate(),
        }),
        ...(data.updatedAt && {
          updatedAt: toDateFirebase(user, data, 'updatedAt').toDate(),
        }),
      });
    }
  });

  return users;
};

function* syncUsersSaga() {
  const user = yield select((state) => state.Auth.admin);
  const usersRef = query(collection(db, 'users'), orderBy('createdAt', 'desc'));

  const task = yield fork(rsf.firestore.syncCollection, usersRef, {
    successActionCreator: usersActions.syncUsersSuccess,
    failureActionCreator: usersActions.syncUsersFailure,
    params: {
      user,
    },
    transform: userTransformer,
  });

  yield take(types.RESET_USER_STATE);
  yield cancel(task);
}

function* userSaga() {
  yield all([
    takeLatest(types.SYNC_USERS.REQUEST, syncUsersSaga),
    takeLeading(types.CREATE_USER.REQUEST, createUserSaga),
    takeLeading(types.UPDATE_USER.REQUEST, updateUserSaga),
  ]);
}

export default userSaga;
