import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import { Form } from '@availity/form';
import { Select, Input } from '../../lib/form-field';
import '@availity/yup';
import * as yup from 'yup';
import moment from 'moment';

import * as callsActions from '../../store/calls/actions';

import { useDispatch } from 'react-redux';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { useTranslation } from 'react-i18next';

const FormCall = (props) => {
  const editingCall = props.call || {};

  const [type, setType] = useState(editingCall.type);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (values.type === 'appointment' && !values.appointmentUserId) {
      values.appointmentUserId = '';
    }

    dispatch(
      props.prevCall
        ? callsActions.createExtraCall(values, props.prevCall.id)
        : callsActions.updateCall({
            id: editingCall.id,
            ...values,
          }),
    );
    props.closeModal();
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={
            props.prevCall
              ? { acquisitionOrderId: props.prevCall.acquisitionOrderId }
              : editingCall
          }
          validationSchema={yup.object().shape({
            acquisitionOrderId: yup.string().required(),
            type: yup.string().required(),
            appointmentDate: yup.date().when('type', {
              is: 'appointment',
              then: () => yup.date().required(),
            }),
            appointmentUserId: yup.string(),
          })}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Row>
                <Col md="6">
                  <Select
                    name="acquisitionOrderId"
                    id="acquisitionOrderId"
                    label="Acquisition Order"
                    isMulti={false}
                    isDisabled
                    options={
                      Array.isArray(props.acquisitionOrders)
                        ? props.acquisitionOrders.map(({ id, name }) => ({
                            label: name,
                            value: id,
                          }))
                        : []
                    }
                  />
                </Col>
                <Col md="3">
                  <Select
                    name="type"
                    id="type"
                    isMulti={false}
                    onChange={(value) => setType(value)}
                    value={type}
                    isDisabled={editingCall.type === 'appointment'}
                    options={[
                      { label: t('Recall'), value: 'recall' },
                      { label: t('Appointment'), value: 'appointment' },
                    ]}
                  />
                </Col>
              </Row>
              <Collapse isOpen={type === 'appointment'}>
                <Row>
                  <Col md="3">
                    <Input
                      id="appointmentDate"
                      name="appointmentDate"
                      label={`${t('Appointment Date')} ${
                        editingCall.appointmentDate &&
                        `: ${moment(editingCall.appointmentDate).format(
                          'DD/MM/YYYY HH:mm',
                        )}`
                      }`}
                      className="form-control"
                      type="datetime-local"
                    />
                  </Col>
                  <Col md="6">
                    <Select
                      name="appointmentUserId"
                      id="appointmentUserId"
                      label={t('Operator')}
                      isMulti={false}
                      options={
                        Array.isArray(props.users)
                          ? [
                              { label: t('None'), value: '' },
                              ...props.users.map(({ id, displayName }) => ({
                                label: displayName,
                                value: id,
                              })),
                            ]
                          : []
                      }
                    />
                  </Col>
                </Row>
              </Collapse>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCall;
