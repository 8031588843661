import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as monetizationOrdersActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase, dateIsInRange } from '../../helpers/sharedFunction';
import toastr from 'toastr';
import moment from 'moment';

const db = getFirestore(rsf.app);

function* createMonetizationOrderSaga({ monetizationOrder }) {
  try {
    const monetizationOrdersRef = collection(db, 'monetizationOrders');
    const companyId = yield select((state) => state.Dashboard.companyId);

    const startDate = moment(monetizationOrder.startDate).format('YYYY-MM-DD');
    const endDate = moment(monetizationOrder.endDate).format('YYYY-MM-DD');

    yield call(rsf.firestore.addDocument, monetizationOrdersRef, {
      ...monetizationOrder,
      companyId,
      startDate: Timestamp.fromDate(new Date(`${startDate} 00:00:00`)),
      endDate: Timestamp.fromDate(new Date(`${endDate} 23:59:59`)),
      createdAt: serverTimestamp(),
    });
    yield put(
      monetizationOrdersActions.createMonetizationOrderSuccess(
        monetizationOrder,
      ),
    );
    toastr.success('MonetizationOrder created!', '');
  } catch (error) {
    yield put(monetizationOrdersActions.createMonetizationOrderFailure(error));
  }
}

function* updateMonetizationOrderSaga({ monetizationOrder }) {
  try {
    const monetizationOrdersRef = doc(
      db,
      'monetizationOrders',
      monetizationOrder.id,
    );
    delete monetizationOrder.id;

    const startDate = moment(monetizationOrder.startDate).format('YYYY-MM-DD');
    const endDate = moment(monetizationOrder.endDate).format('YYYY-MM-DD');

    yield call(rsf.firestore.setDocument, monetizationOrdersRef, {
      ...monetizationOrder,
      startDate: Timestamp.fromDate(new Date(`${startDate} 00:00:00`)),
      endDate: Timestamp.fromDate(new Date(`${endDate} 23:59:59`)),
      createdAt: Timestamp.fromDate(monetizationOrder.createdAt),
      updatedAt: serverTimestamp(),
    });
    yield put(monetizationOrdersActions.updateMonetizationOrderSuccess());
    toastr.success('MonetizationOrder updated!', '');
  } catch (error) {
    yield put(monetizationOrdersActions.updateMonetizationOrderFailure(error));
  }
}

const monetizationOrderTransformer = (payload) => {
  let monetizationOrders = [];

  payload.forEach((monetizationOrder) => {
    const data = monetizationOrder.data();

    const startDate = toDateFirebase(payload, data, 'startDate').toDate();
    const endDate = toDateFirebase(payload, data, 'endDate').toDate();

    monetizationOrders.push({
      id: monetizationOrder.id,
      ...data,
      startDate,
      endDate,
      active: dateIsInRange(new Date(), startDate, endDate),
      ...(data.createdAt && {
        createdAt: toDateFirebase(monetizationOrder, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(
          monetizationOrder,
          data,
          'updatedAt',
        ).toDate(),
      }),
    });
  });

  return monetizationOrders;
};

function* syncMonetizationOrdersSaga() {
  const companyId = yield select((state) => state.Dashboard.companyId);
  const monetizationOrdersRef = query(
    collection(db, 'monetizationOrders'),
    where('companyId', '==', companyId),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, monetizationOrdersRef, {
    successActionCreator:
      monetizationOrdersActions.syncMonetizationOrdersSuccess,
    failureActionCreator:
      monetizationOrdersActions.syncMonetizationOrdersFailure,
    transform: (payload) => monetizationOrderTransformer(payload),
  });

  yield take(types.RESET_MONETIZATION_ORDER_STATE);
  yield cancel(task);
}

function* monetizationOrderSaga() {
  yield all([
    takeLatest(
      types.SYNC_MONETIZATION_ORDERS.REQUEST,
      syncMonetizationOrdersSaga,
    ),
    takeLeading(
      types.CREATE_MONETIZATION_ORDER.REQUEST,
      createMonetizationOrderSaga,
    ),
    takeLatest(
      types.UPDATE_MONETIZATION_ORDER.REQUEST,
      updateMonetizationOrderSaga,
    ),
  ]);
}

export default monetizationOrderSaga;
