import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  Button,
  Table,
} from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import * as campaignsActions from '../../store/campaigns/actions';
import * as leadsActions from '../../store/leads/actions';
import { selectCampaignsWithActiveAcquisitionOrder } from '../../selectors/campaign';
import { leadsWithoutPerson as leadsWithoutPersonSelector } from '../../selectors/lead';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Dots } from 'react-activity';

import { components } from 'react-select';
import { capitalize } from '../../helpers/sharedFunction';

const { Option } = components;

const CampaignSelectOption = (props) => (
  <Option {...props}>
    {capitalize(props.data.source)} - {props.data.label}
  </Option>
);

const DuplicateLeads = ({ leads }) => {
  const [campaignId, setCampaignId] = useState(null);
  const [sent, setSent] = useState(false);

  const campaigns = useSelector(selectCampaignsWithActiveAcquisitionOrder);
  const leadsWithoutPerson = useSelector(leadsWithoutPersonSelector);
  const excludedLeads = leadsWithoutPerson.map((lead) => lead.id);

  console.log('excludedLeads', excludedLeads);

  leads = leads.filter((lead) => !excludedLeads.includes(lead.id));

  const loading = useSelector((state) => state.Leads.loading);
  const logs = useSelector((state) => state.Leads.logs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const handleDuplicateLeads = () => {
    dispatch(leadsActions.duplicateLeads(campaignId, leads));
    setSent(true);
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            campaignId,
          }}
          validationSchema={yup.object().shape({
            campaignId: yup.string().required(),
          })}>
          {({ values }) => (
            <>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="campaignId">{t('Campaign')}:</Label>
                    <Select
                      name="campaignId"
                      id="campaignId"
                      isMulti={false}
                      onChange={(campaign) => setCampaignId(campaign.value)}
                      value={campaignId}
                      isDisabled={sent}
                      raw
                      options={campaigns.map((campaign) => ({
                        label: campaign.name,
                        value: campaign.id,
                        source: campaign.source,
                      }))}
                      components={{ Option: CampaignSelectOption }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Collapse isOpen={!!campaignId}>
                <Row className="my-2">
                  <Col md="12">
                    <h4 className="text-center">
                      {t('duplicate_leads', { countLeads: leads.length })}
                    </h4>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md="12" className="text-center">
                    <Button
                      color="warning"
                      outline
                      className="waves-effect waves-light"
                      onClick={handleDuplicateLeads}
                      disabled={!leads.length || sent}>
                      <i className="bx bx-copy-alt font-size-20 align-middle mr-2"></i>
                      {t('Duplicate Leads')}
                    </Button>
                  </Col>
                </Row>
                {!!(sent && loading) && <Dots />}
                {!!(sent && logs.length) && (
                  <Row className="my-2">
                    <Col md="12">
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Lead ID</th>
                              <th>Response</th>
                            </tr>
                          </thead>
                          <tbody>
                            {logs.map((log, index) => (
                              <tr key={log.leadId}>
                                <th scope="row">{index}</th>
                                <td>{log.leadId}</td>
                                <td>{log.response}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Collapse>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default DuplicateLeads;
