import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasRole } from '../../selectors/auth';
import { Link, useLocation } from 'react-router-dom';
import { toggleLeftMenu, changeSidebarType } from '../../store/layout/actions';
import CompanyBox from '../Common/CompanyBox';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import NotificationMakeCalls from '../Calls/NotificationMakeCalls';
import NextCallTime from '../Calls/NextCallTime';
import DataEntryLinkDropdown from '../MonetizationOrders/DataEntryLinkDropdown';
import ManagePhone from '../Calls/ManagePhone';
import CallStopwatch from '../Calls/CallStopwatch';
import ToggleSensitiveData from '../Common/ToggleSensitiveData';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

import logo from '../../assets/images/logo-sm-light.png';
import logoLightPng from '../../assets/images/logo-light.png';
import logoDark from '../../assets/images/logo-dark.png';
import roles from '../../config/roles';

const Header = () => {
  const location = useLocation();

  const companyId = useSelector((state) => state.Dashboard.companyId);
  const isAdminAndOperator = useSelector(
    hasRole([
      roles.SUPER_ADMIN,
      roles.ADMIN,
      roles.MANAGER,
      roles.TEAM_LEADER,
      roles.OPERATOR,
    ]),
  );
  const { leftMenu, leftSideBarType } = useSelector((state) => state.Layout);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const dispatch = useDispatch();

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen)
        document.documentElement.requestFullscreen();
      else if (document.documentElement.mozRequestFullScreen)
        document.documentElement.mozRequestFullScreen();
      else if (document.documentElement.webkitRequestFullscreen)
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
    } else {
      if (document.cancelFullScreen) document.cancelFullScreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen)
        document.webkitCancelFullScreen();
    }
  };

  const tToggle = () => {
    dispatch(toggleLeftMenu(!leftMenu));
    if (leftSideBarType === 'default')
      dispatch(changeSidebarType('condensed', isMobile));
    else if (leftSideBarType === 'condensed')
      dispatch(changeSidebarType('default', isMobile));
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="32" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="32" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={tToggle}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {companyId && <CompanyBox companyId={companyId} />}
          </div>

          <div className="d-flex">
            {isAdminAndOperator &&
              companyId &&
              location.pathname !== '/calls/manage' && <NextCallTime />}
            {isAdminAndOperator &&
              companyId &&
              location.pathname !== '/calls/manage' && (
                <NotificationMakeCalls />
              )}
            {isAdminAndOperator &&
              companyId &&
              location.pathname === '/calls/manage' && <CallStopwatch />}
            {isAdminAndOperator &&
              companyId &&
              location.pathname === '/calls/manage' && <ManagePhone />}
            {isAdminAndOperator &&
              companyId &&
              location.pathname === '/calls/manage' &&
              false && <DataEntryLinkDropdown />}

            {!isMobile && (
              <>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    onClick={toggleFullscreen}
                    className="btn header-item noti-icon waves-effect"
                    data-toggle="fullscreen">
                    <i className="bx bx-fullscreen"></i>
                  </button>
                </div>
                <LanguageDropdown />
              </>
            )}

            <ToggleSensitiveData />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
