export const MULTI_CAMPAIGN_IMPORT = {
  name: 'Multi',
  value: '__multi__',
};

export const IMPORT_LEAD_TYPES = [
  {
    label: 'Default',
    value: 'default',
    companyIds: ['s1rUAJRNBBOXzuCxvkQl'],
    active: false,
  },
  {
    label: 'IBL Restart',
    value: 'IBLRestart',
    companyIds: ['fFeBcu2qabkcEmhxLpat'],
    requiredFields: ['Nome', 'Cognome', 'Telefono'],
    active: true,
  },
  {
    label: 'Limoni Restart',
    value: 'limoniRestart',
    companyIds: ['fFeBcu2qabkcEmhxLpat'],
    active: false,
  },
  {
    label: 'La carica dei 251',
    value: 'import251',
    companyIds: ['s1rUAJRNBBOXzuCxvkQl'],
    active: false,
  },
  {
    label: 'Haier Restart',
    value: 'haierRestart',
    companyIds: ['fFeBcu2qabkcEmhxLpat'],
    requiredFields: [
      'phone_number',
      'email',
      'first_name',
      'last_name',
      'zip_code',
    ],
    active: true,
  },
  {
    label: 'Google Lead Form',
    value: 'googleLeadForm',
    companyIds: ['s1rUAJRNBBOXzuCxvkQl'],
    requiredFields: [
      'Ora di invio UTC',
      'ID campagna',
      'ID gruppo di annunci',
      'FIRST_NAME',
      'LAST_NAME',
      'EMAIL',
      'PHONE_NUMBER',
    ],
    active: true,
  },
  {
    label: 'Compass Recall',
    value: 'compassRecall',
    companyIds: ['s1rUAJRNBBOXzuCxvkQl', 'Jn4dfqPSy8bQ8lVbvH0d'],
    requiredFields: ['nome', 'cognome', 'cell'],
    active: true,
  },
  {
    label: 'Compass FB Ads',
    value: 'compassFBAds',
    companyIds: ['s1rUAJRNBBOXzuCxvkQl'],
    requiredFields: ['full_name', 'email', 'phone_number', 'created_time'],
    active: true,
  },
  {
    label: 'Compass POL',
    value: 'compassPOL',
    companyIds: ['s1rUAJRNBBOXzuCxvkQl', 'Jn4dfqPSy8bQ8lVbvH0d'],
    requiredFields: ['Nome', 'Cognome', 'Telefono'],
    active: true,
  },
  {
    label: 'Trovo Bici',
    value: 'trovoBici',
    companyIds: ['s1rUAJRNBBOXzuCxvkQl'],
    requiredFields: ['AZIENDA', 'TELEFONO', 'CAP'],
    active: true,
  },
  {
    label: 'Facebook Ads',
    value: 'facebookAds',
    companyIds: ['s1rUAJRNBBOXzuCxvkQl'],
    requiredFields: [
      'first_name',
      'last_name',
      'phone_number',
      'ad_id',
      'adset_id',
      'campaign_id',
      'form_id',
      'form_name',
      'marketing_consent',
      'third_party_consent',
      'created_time',
    ],
    active: true,
  },
];
