import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as customersActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

function* createCustomerSaga({ customer }) {
  try {
    const customersRef = collection(db, 'customers');
    const companyId = yield select((state) => state.Dashboard.companyId);

    yield call(rsf.firestore.addDocument, customersRef, {
      ...customer,
      companyId,
      active: true,
      createdAt: serverTimestamp(),
    });
    yield put(customersActions.createCustomerSuccess(customer));
    toastr.success('Customer created!', '');
  } catch (error) {
    yield put(customersActions.createCustomerFailure(error));
  }
}

function* updateCustomerSaga({ customer }) {
  try {
    const customerRef = doc(db, 'customers', customer.id);
    delete customer.id;

    yield call(
      rsf.firestore.setDocument,
      customerRef,
      {
        ...customer,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(customersActions.updateCustomerSuccess(customer));
    toastr.success('Customer updated!', '');
  } catch (error) {
    yield put(customersActions.updateCustomerFailure(error));
  }
}

const customerTransformer = (payload) => {
  let customers = [];

  payload.forEach((customer) => {
    const data = customer.data();

    customers.push({
      id: customer.id,
      ...data,
      createdAt: toDateFirebase(payload, data).toDate(),
    });
  });

  return customers;
};

function* syncCustomersSaga() {
  const companyId = yield select((state) => state.Dashboard.companyId);
  const customersRef = query(
    collection(db, 'customers'),
    where('companyId', '==', companyId),
    orderBy('name', 'asc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, customersRef, {
    successActionCreator: customersActions.syncCustomersSuccess,
    failureActionCreator: customersActions.syncCustomersFailure,
    transform: (payload) => customerTransformer(payload),
  });

  yield take(types.RESET_CUSTOMER_STATE);
  yield cancel(task);
}

function* customerSaga() {
  yield all([
    takeLatest(types.SYNC_CUSTOMERS.REQUEST, syncCustomersSaga),
    takeLeading(types.CREATE_CUSTOMER.REQUEST, createCustomerSaga),
    takeLeading(types.UPDATE_CUSTOMER.REQUEST, updateCustomerSaga),
  ]);
}

export default customerSaga;
