import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useSelector } from 'react-redux';
import { drawSeriesDailyGraph } from '../../selectors/lead';
import ReactApexChart from 'react-apexcharts';
import colors from '../CommonStyle/colors';
import { useTranslation } from 'react-i18next';

const LeadsGraph = ({ dateRange }) => {
  const { t } = useTranslation();

  const dataGraph = useSelector(drawSeriesDailyGraph(dateRange, t));

  const dailyGraph = {
    series: dataGraph.series,
    options: {
      chart: {
        toolbar: 'false',
        type: 'line',
        stacked: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dddd, dd MMM yyyy',
        },
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 10,
          offsetX: 20,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        curve: 'smooth',
        width: [3],
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: colors.dark,
          },
        ],
      },
      yaxis: [
        {
          seriesName: dataGraph.series[0].name,
          tickAmount: 5,
          max: parseInt(dataGraph.max),
          min: parseInt(dataGraph.min),
          forceNiceScale: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[0].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[0].color,
            },
          },
          title: {
            text: dataGraph.series[0].name,
            style: {
              color: dataGraph.series[0].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">
            {t('Daily Leads')}
          </CardTitle>
          <div className="clearfix"></div>
          <div id="line-chart" className="apex-charts" dir="ltr">
            <ReactApexChart
              series={dailyGraph.series}
              options={dailyGraph.options}
              type="line"
              height={245}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LeadsGraph;
