const blue = '#556ee6';
const indigo = '#564ab1';
const purple = '#6f42c1';
const pink = '#e83e8c';
const red = '#f46a6a';
const orange = '#f1734f';
const yellow = '#f1b44c';
const green = '#34c38f';
const teal = '#050505';
const cyan = '#50a5f1';
const gray = '#74788d';
const dark = '#343a40';

const white = '#fff';
const gray_100 = '#212529';
const gray_200 = '#2a3042';
const gray_300 = '#32394e';
const gray_400 = '#a6b0cf';
const gray_500 = '#bfc8e2';
const gray_600 = '#c3cbe4';
const gray_700 = '#f6f6f6';
const gray_800 = '#eff2f7';
const gray_900 = '#f8f9fa';
const black = '#000';

const colors = {
    primary: blue,
    secondary: gray,
    success: green,
    info: cyan,
    warning: yellow,
    danger: red,
    light: gray_200,
    dark: dark,
};

export default colors;
