import React, { useState } from 'react';
import { Container } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
  isTeamLeader as isTeamLeaderSelector,
  isOperator as isOperatorSelector,
  isCustomer as isCustomerSelector,
  isSale as isSaleSelector,
  isPublisher as isPublisherSelector,
} from '../../selectors/auth';

// Pages Components
import SelectCompanyAlert from '../../components/Dashboard/SelectCompanyAlert';
import AdminDashboard from './admin';
import OperatorDashboard from './operator';
import CustomerDashboard from './customer';
import SaleDashboard from './sale';
import PublisherDashboard from './publisher';

const Dashboard = (props) => {
  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isTeamLeader = useSelector(isTeamLeaderSelector);
  const isOperator = useSelector(isOperatorSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const isSale = useSelector(isSaleSelector);
  const isPublisher = useSelector(isPublisherSelector);

  const companyId = useSelector((state) => state.Dashboard.companyId);
  const [companyAlert, setCompanyAlert] = useState(!companyId);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {!companyId ? (
            <SelectCompanyAlert
              isOpen={companyAlert}
              onCancel={() => setCompanyAlert(false)}
            />
          ) : (
            <>
              {isAdmin && <AdminDashboard />}
              {(isOperator || isTeamLeader || isManager) && (
                <OperatorDashboard />
              )}
              {isCustomer && <CustomerDashboard />}
              {isSale && <SaleDashboard />}
              {isPublisher && <PublisherDashboard />}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
