import React from 'react';
import {
  ViewButton,
  EditButton,
  TestQueryButton,
  GoogleSpreadsheetButton,
} from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel, getSort } from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (
  customers,
  sales,
  openViewModal,
  openEditModal,
  openTestQueryModal,
) => [
  {
    title: 'Name',
    name: 'name',
    width: 240,
  },
  {
    title: 'Customer',
    name: 'customerId',
    getCellValue: (row) => getLabel(row.customerId, customers),
  },
  {
    title: 'Sale',
    name: 'supplierId',
    getCellValue: (row) => getLabel(row.supplierId, sales),
  },
  {
    title: 'Start Date',
    name: 'startDate',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
    exportValue: ({ value }) => moment(value).format('DD/MM/YYYY'),
  },
  {
    title: 'End Date',
    name: 'endDate',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
    exportValue: ({ value }) => moment(value).format('DD/MM/YYYY'),
  },
  {
    title: 'Active',
    name: 'active',
    width: 100,
    getCellValue: (row) => (row.active ? 'yes' : 'no'),
    formatterComponent: ({ value }) =>
      value === 'yes' ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
  },
  {
    title: 'Payout',
    name: 'payout',
    width: 100,
    formatterComponent: ({ value }) => `€ ${value}`,
  },
  {
    title: 'Positive Closure',
    name: 'costPositiveClosure',
    width: 100,
    formatterComponent: ({ value }) => `€ ${value}`,
  },
  {
    title: 'Action',
    name: 'action',
    width: 180,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        {row.googleSpreadsheetId !== 'processing' && (
          <EditButton row={row} onPress={openEditModal} />
        )}
        {row.query !== '' && (
          <TestQueryButton row={row} onPress={openTestQueryModal} />
        )}
        {row.googleSpreadsheetId &&
          !['new', 'processing', 'error'].includes(row.googleSpreadsheetId) && (
            <GoogleSpreadsheetButton row={row} />
          )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
