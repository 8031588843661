import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as customersActions from '../../store/customers/actions';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import * as campaignsActions from '../../store/campaigns/actions';
import * as messageTemplatesActions from '../../store/messageTemplates/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCampaignsForAcquisitionOrderSelect } from '../../selectors/campaign';
import { createQueryBuilderConfig } from '../../selectors/leadField';

import Modal from '../../components/Common/Modal/Modal';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormAcquisitionOrder from '../../components/AcquisitionOrders/FormAcquisitionOrder';
import LeadsQueryTable from '../../components/Leads/LeadsQueryTable';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/AcquisitionOrders/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

const List = (props) => {
  const loadingAcquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.loading,
  );
  const loadingCustomers = useSelector((state) => state.Customer.loading);
  const loadingCampaigns = useSelector((state) => state.Campaign.loading);
  const loadingMessageTemplates = useSelector(
    (state) => state.MessageTemplate.loading,
  );
  const loading =
    loadingAcquisitionOrders ||
    loadingCustomers ||
    loadingCampaigns ||
    loadingMessageTemplates;

  const customers = useSelector((state) => state.Customer.customers);
  const messageTemplates = useSelector(
    (state) => state.MessageTemplate.messageTemplates,
  );
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const campaigns = useSelector((state) => state.Campaign.campaigns);

  const leadQueryConfig = useSelector(createQueryBuilderConfig());

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedAcquisitionOrder, setSelectedAcquisitionOrder] =
    useState(null);
  const [testQueryModal, setTestQueryModal] = useState(false);

  const campaignsForSelect = useSelector(
    selectCampaignsForAcquisitionOrderSelect(selectedAcquisitionOrder),
  );

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
    dispatch(customersActions.syncCustomers());
    dispatch(campaignsActions.syncCampaigns());
    dispatch(messageTemplatesActions.syncMessageTemplates());
  }, []);

  const openViewModal = (acquisitionOrder) => {
    setSelectedAcquisitionOrder(acquisitionOrder);
    setViewModal(true);
  };

  const openEditModal = (acquisitionOrder) => {
    setSelectedAcquisitionOrder(acquisitionOrder);
    setEditModal(true);
  };

  const openTestQueryModal = (acquisitionOrder) => {
    setSelectedAcquisitionOrder(acquisitionOrder);
    setTestQueryModal(true);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        messageTemplates,
        customers,
        campaigns,
        leadQueryConfig,
        openViewModal,
        openEditModal,
        openTestQueryModal,
      ),
    [messageTemplates, customers, campaigns, leadQueryConfig],
  );

  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Acquisition Order" breadcrumbItem="List" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Acquisition Order"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={acquisitionOrders}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Acquisition Order"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedAcquisitionOrder} />
          </Modal>
          <Modal
            size="xl"
            title="Acquisition Order"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormAcquisitionOrder
              closeModal={() => setEditModal(false)}
              create={!selectedAcquisitionOrder}
              loading={loadingCustomers || loadingAcquisitionOrders}
              customers={customers}
              campaigns={campaignsForSelect}
              messageTemplates={messageTemplates}
              acquisitionOrder={selectedAcquisitionOrder}
            />
          </Modal>
          <Modal
            size="xl"
            title="Test Query Leads"
            isOpen={testQueryModal}
            toggle={() => setTestQueryModal(!testQueryModal)}
            showClose
            scrollable={false}>
            <LeadsQueryTable
              query={selectedAcquisitionOrder?.jsonLogicQuery}
              queryConfig={leadQueryConfig}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
