import React, { useState, useCallback } from 'react';
import { Row, Col } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { userSaleId } from '../../selectors/auth';
import { countMonetizationOrdersBySale } from '../../selectors/monetizationOrder';
import * as dailyInsightsActions from '../../store/suppliers/dailySaleInsights/actions';

// Pages Components
import SaleMetrics from '../../components/Suppliers/SaleMetrics';
import RevenuesGraph from '../../components/Dashboard/Sale/RevenuesGraph';
import MonetizationItemsInsights from '../../components/Dashboard/Sale/MonetizationItemsInsights';
import UserCard from '../../components/Common/UserCard';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const SaleDashboard = (props) => {
  const supplierId = useSelector(userSaleId);
  const countMonetizationOrders = useSelector(
    countMonetizationOrdersBySale(supplierId),
  );
  const countActiveMonetizationOrders = useSelector(
    countMonetizationOrdersBySale(supplierId, true),
  );

  const loading = useSelector((state) => state.DailySaleInsights.loading);

  const startDate = useSelector((state) => state.DailySaleInsights.startDate);
  const endDate = useSelector((state) => state.DailySaleInsights.endDate);
  const filters = {
    supplierId,
    monetizationOrderIds: null,
  };

  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('01/01/2021', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    supplierId: filters?.supplierId || supplierId || '',
    monetizationOrderIds: filters?.monetizationOrderIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(
    ({ startDate, endDate, supplierId, monetizationOrderIds }) => {
      const selectedStartDate = startDate.utc().startOf('day').toDate();
      const selectedEndDate = endDate.utc().endOf('day').toDate();
      dispatch(
        dailyInsightsActions.fetchDailyInsights(
          selectedStartDate,
          selectedEndDate,
          {
            monetizationOrderIds,
            supplierId,
          },
        ),
      );
      setSelectedFilters({
        startDate,
        endDate,
        monetizationOrderIds,
        supplierId,
      });
    },
  );

  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        title={t('Dashboard')}
        onRefresh={() => refresh(selectedFilters)}
        loading={loading}
      />
      <Row>
        <Col md="12">
          <UserCard
            firstBoxLabel="Total Monetization Orders"
            firstBoxValue={countMonetizationOrders}
            secondBoxLabel="Active Monetization Orders"
            secondBoxValue={countActiveMonetizationOrders}
            pathLink="/suppliers/dailySaleInsights"
            iconLink="bx bx-bar-chart-square"
            textLink="Daily Insights"
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showSaleFilters
            initialFilters={selectedFilters}
          />
        </Col>
      </Row>

      <Row>
        <SaleMetrics />
      </Row>
      <Row>
        <Col md="12">
          <RevenuesGraph dateRange={selectedFilters} />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <MonetizationItemsInsights supplierId={supplierId} />
        </Col>
      </Row>
    </>
  );
};

export default SaleDashboard;
