const statsTypes = {
  RESET_DAILY_COMPANY_INSIGHT_STATE: 'RESET_DAILY_COMPANY_INSIGHT_STATE',
  DAILY_COMPANY_INSIGHTS: {
    REQUEST: 'DAILY_COMPANY_INSIGHTS.REQUEST',
    SUCCESS: 'DAILY_COMPANY_INSIGHTS.SUCCESS',
    FAILURE: 'DAILY_COMPANY_INSIGHTS.FAILURE',
  },
};

export default statsTypes;
