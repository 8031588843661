import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logo from '../../assets/images/logo-sm-light.png';
import { useTranslation } from 'react-i18next';

const UserCard = (props) => {
  const user = useSelector((state) => state.Auth.admin);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <Media>
                    <div className="mr-3">
                      <img
                        src={user.photoURL || logo}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media className="align-self-center" body>
                      <div className="text-muted">
                        <p className="mb-2">{t('Welcome to')} Chiedimi CRM</p>
                        <h5 className="mb-1">{user.displayName}</h5>
                        <p className="mb-0">{t(user.role)}</p>
                      </div>
                    </Media>
                  </Media>
                </Col>

                <Col lg="4" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="6">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            {t(props.firstBoxLabel)}
                          </p>
                          <h5 className="mb-0">{props.firstBoxValue}</h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            {t(props.secondBoxLabel)}
                          </p>
                          <h5 className="mb-0">{props.secondBoxValue}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                {props.pathLink && (
                  <Col lg="4" className="align-self-center text-lg-center">
                    <Link
                      to={props.pathLink}
                      className="btn btn-info waves-effect waves-light">
                      <i
                        className={`${props.iconLink} font-size-20 align-middle mr-2`}></i>
                      {t(props.textLink)}
                    </Link>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserCard;
