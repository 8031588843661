import {
  takeEvery,
  put,
  all,
  call,
  takeLeading,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as smsMessagesActions from './actions';
import { getFirestore, collection, serverTimestamp } from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

const smsMessageTransformer = (smsMessage, data) => ({
  id: smsMessage.id,
  ...data,
  cost: parseFloat(data.cost || '0'),
  ...(data.sendAt && {
    sendAt: toDateFirebase(smsMessage, data, 'sendAt').toDate(),
  }),
  ...(data.createdAt && {
    createdAt: toDateFirebase(smsMessage, data).toDate(),
  }),
  ...(data.updatedAt && {
    updatedAt: toDateFirebase(smsMessage, data, 'updatedAt').toDate(),
  }),
});

function* createSmsMessageSaga({ smsMessage }) {
  try {
    const db = getFirestore(rsf.app);
    const smsMessagesRef = collection(db, 'sms');
    const companyId = yield select((state) => state.Dashboard.companyId);

    yield call(rsf.firestore.addDocument, smsMessagesRef, {
      ...smsMessage,
      companyId,
      createdAt: serverTimestamp(),
    });
    yield put(smsMessagesActions.createSmsMessageSuccess(smsMessage));
    toastr.success('SmsMessage created!', '');
  } catch (error) {
    yield put(smsMessagesActions.createSmsMessageFailure(error));
  }
}

function* fetchSmsMessagesSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    const smsMessagesRef = rsf.firestore.createCollectionRefWithFilters(
      'sms',
      companyId,
      startDate,
      endDate,
      filters,
    );

    const smsMessagesSnap = yield call(
      rsf.firestore.getCollection,
      smsMessagesRef,
    );

    let smsMessages = [];

    smsMessagesSnap.forEach((smsMessage) => {
      const data = smsMessage.data();

      smsMessages.push(smsMessageTransformer(smsMessage, data));
    });

    yield put(
      smsMessagesActions.fetchSmsMessagesSuccess(
        smsMessages,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(smsMessagesActions.fetchSmsMessagesFailure(error));
  }
}

function* smsMessageSaga() {
  yield all([
    takeEvery(types.FETCH_SMS_MESSAGES.REQUEST, fetchSmsMessagesSaga),
    takeLeading(types.CREATE_SMS_MESSAGE.REQUEST, createSmsMessageSaga),
  ]);
}

export default smsMessageSaga;
