import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabels,
  getSort,
} from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (
  openViewModal,
  openEditModal,
  acquisitionOrders,
  user,
  companyId,
) => [
  {
    title: 'Display Name',
    name: 'displayName',
    width: 150,
  },
  {
    title: 'Email',
    name: 'email',
    width: 200,
  },
  {
    title: 'Role',
    name: 'role',
    width: 120,
  },
  {
    title: 'Exchange Number',
    name: 'phoneExchangeId',
    width: 150,
  },
  {
    title: 'Acquisition Orders',
    name: 'acquisitionOrderIds',
    getCellValue: (row) =>
      getLabels(row.acquisitionOrderIds, acquisitionOrders),
  },
  {
    title: 'Active',
    name: 'active',
    width: 100,
    getCellValue: (row) => (row.active ? 'yes' : 'no'),
    formatterComponent: ({ value }) =>
      value === 'yes' ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
  },
  {
    title: 'Created At',
    name: 'createdAt',
    width: 120,
    formatterComponent: ({ value }) => `${moment(value).fromNow()}`,
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(`${moment(value).fromNow()}`, filter.value),
    exportValue: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    title: 'Action',
    name: 'action',
    width: 120,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        {(user.role !== row.role || user.id === row.id) && (
          <EditButton row={row} onPress={openEditModal} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
