import React, { useState } from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import * as callsActions from '../../../store/calls/actions';
import * as leadsActions from '../../../store/leads/actions';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../../Common/Modal/Alert';
import { useTranslation } from 'react-i18next';
import { getFirestore, collection, doc } from 'firebase/firestore';
import rsf from '../../../helpers/firebase';

const AppointmentAlert = ({ call, lead, isOpen, onCancel }) => {
  const [appointmentDate, setAppointmentDate] = useState(
    call.appointmentDate || new Date(),
  );
  const [isPersonal, setIsPersonal] = useState(
    call.appointmentUserId
      ? call.appointmentUserId === ''
        ? 'no'
        : 'yes'
      : 'no',
  );
  const [disabledAlertBtn, setDisabledAlertBtn] = useState(false);

  const userId = useSelector((state) => state.Auth.admin.id);

  const dispatch = useDispatch();

  const handleScheduleAppointment = () => {
    setDisabledAlertBtn(true);
    const newCallId = doc(collection(getFirestore(rsf.app), 'calls')).id;

    dispatch(
      callsActions.updateCall({
        id: call.id,
        outcome: 'appointment',
        status: 'closed',
        endDateCall: new Date(),
        nextCall: newCallId,
        userId,
      }),
    );

    dispatch(
      callsActions.createCall({
        id: newCallId,
        userId: '',
        leadId: lead.id,
        leadName: lead.name,
        phone: lead.phone,
        campaignId: call.campaignId,
        companyId: call.companyId,
        acquisitionOrderId: call.acquisitionOrderId,
        sector: call.sector,
        status: 'new',
        type: 'appointment',
        appointmentDate,
        appointmentUserId: isPersonal === 'yes' ? userId : '',
        count: call.count,
        inbound: false,
        prevCall: call.id,
        nextCall: '',
      }),
    );
    dispatch(
      leadsActions.updateLead({
        id: call.leadId,
        qualified: 'readyToQualify',
        callId: newCallId,
      }),
    );
    onCancel();
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={isOpen}
      title={`${t('Schedule appointment with')}: ${lead ? lead.name : ''}`}
      confirmBtnLabel="Create"
      onCancel={onCancel}
      onConfirm={handleScheduleAppointment}
      disabledBtn={disabledAlertBtn}>
      <>
        <FormGroup>
          <Label htmlFor="appointmentDate">{t('Date')}:</Label>
          <Input
            id="appointmentDate"
            name="appointmentDate"
            className="form-control"
            type="datetime-local"
            value={appointmentDate}
            onChange={(e) => setAppointmentDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="isPersonal">{t('Personal appointment')}?</Label>
          <Input
            type="select"
            name="isPersonal"
            id="isPersonal"
            onChange={(e) => setIsPersonal(e.target.value)}
            value={isPersonal}>
            <option value="yes">{t('Yes')}</option>
            <option value="no">{t('No')}</option>
          </Input>
        </FormGroup>
      </>
    </Alert>
  );
};

export default AppointmentAlert;
