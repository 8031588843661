import React, { useState, useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import * as dashboardActions from '../../store/dashboard/actions';
import * as companiesActions from '../../store/companies/actions';
import { availableCompanies } from '../../selectors/company';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../Common/Modal/Alert';
import { useTranslation } from 'react-i18next';

const SelectCompanyAlert = (props) => {
  const [companyId, setCompanyId] = useState('');
  const [disabledAlertBtn, setDisabledAlertBtn] = useState(true);

  const companies = useSelector(availableCompanies);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(companiesActions.syncCompanies());
  }, []);

  const onConfirm = () => {
    setDisabledAlertBtn(true);
    dispatch(dashboardActions.setCompany(companyId));
    props.onCancel();
  };

  const handleCampaign = (value) => {
    setCompanyId(value);
    setDisabledAlertBtn(false);
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={props.isOpen}
      title={t('Choose the company')}
      mainIcon={
        <div className="avatar-sm mx-auto my-2">
          <span className="avatar-title rounded-circle bg-info font-size-24">
            <i className={`bx bxs-shapes`}></i>
          </span>
        </div>
      }
      confirmBtnLabel={t('Confirm')}
      onConfirm={onConfirm}
      disabledBtn={disabledAlertBtn}>
      <Form
        initialValues={{ companyId }}
        validationSchema={yup.object().shape({
          companyId: yup.string().required(),
        })}>
        <FormGroup>
          <Select
            name="companyId"
            id="companyId"
            isMulti={false}
            onChange={handleCampaign}
            value={companyId}
            options={companies.map((company) => ({
              label: company.name,
              value: company.id,
            }))}
          />
        </FormGroup>
      </Form>
    </Alert>
  );
};

export default SelectCompanyAlert;
