import { all } from 'redux-saga/effects';

//public
import AuthSaga from './auth/saga';
import DashboardSaga from './dashboard/saga';
import LayoutSaga from './layout/saga';
import LeadsSaga from './leads/saga';
import PeopleSaga from './people/saga';
import AudiencesSaga from './audiences/saga';
import DailyLeadInsightsSaga from './leads/dailyInsights/saga';
import DailyFBCampaignInsightsSaga from './facebookAd/dailyCampaignInsights/saga';
import DailyGoogleCampaignInsightsSaga from './googleAd/dailyCampaignInsights/saga';
import CustomerSaga from './customers/saga';
import DailyCustomerInsightsSaga from './customers/dailyInsights/saga';
import AcquisitionOrderSaga from './acquisitionOrders/saga';
import MonetizationOrderSaga from './monetizationOrders/saga';
import DailyMonetizationOrderInsightsSaga from './monetizationOrders/dailyInsights/saga';
import MonetizationItemSaga from './monetizationItems/saga';
import MarketingCampaignSaga from './marketingCampaigns/saga';
import LeadFieldSaga from './leadFields/saga';
import LeadFormSaga from './leadForms/saga';
import CampaignSaga from './campaigns/saga';
import DailyCampaignInsightsSaga from './campaigns/dailyInsights/saga';
import SurveySaga from './surveys/saga';
import SurveyFormSaga from './surveyForms/saga';
import MessageTemplateSaga from './messageTemplates/saga';
import SmsMessageSaga from './smsMessages/saga';
import PushNotificationSaga from './pushNotifications/saga';
import CallSaga from './calls/saga';
import CallInsightSaga from './callInsights/saga';
import NextCallSaga from './nextCall/saga';
import SupplierSaga from './suppliers/saga';
import DailySaleInsightsSaga from './suppliers/dailySaleInsights/saga';
import DailyPublisherInsightsSaga from './suppliers/dailyPublisherInsights/saga';
import UserSaga from './users/saga';
import CompanySaga from './companies/saga';
import DailyCompanyInsightsSaga from './companies/dailyInsights/saga';

export default function* rootSaga() {
  yield all([
    //public
    AuthSaga(),
    LayoutSaga(),
    LeadsSaga(),
    PeopleSaga(),
    AudiencesSaga(),
    DailyLeadInsightsSaga(),
    DailyFBCampaignInsightsSaga(),
    DailyGoogleCampaignInsightsSaga(),
    CustomerSaga(),
    DailyCustomerInsightsSaga(),
    AcquisitionOrderSaga(),
    MonetizationOrderSaga(),
    DailyMonetizationOrderInsightsSaga(),
    MonetizationItemSaga(),
    MarketingCampaignSaga(),
    LeadFieldSaga(),
    LeadFormSaga(),
    CampaignSaga(),
    DailyCampaignInsightsSaga(),
    SurveySaga(),
    SurveyFormSaga(),
    MessageTemplateSaga(),
    SmsMessageSaga(),
    PushNotificationSaga(),
    CallSaga(),
    CallInsightSaga(),
    NextCallSaga(),
    SupplierSaga(),
    DailySaleInsightsSaga(),
    DailyPublisherInsightsSaga(),
    UserSaga(),
    DashboardSaga(),
    CompanySaga(),
    DailyCompanyInsightsSaga(),
  ]);
}
