import { takeEvery, select, put, all, call } from 'redux-saga/effects';
import types from './actionTypes';
import * as dailyInsightsActions from './actions';
import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import { selectCampaignsBySector } from '../../../selectors/campaign';

import {
  toDateFirebase,
  arrayChunks,
  isNullish,
} from '../../../helpers/sharedFunction';

export const dailyInsightTransformer = (stat, data) => {
  const date = toDateFirebase(stat, data, 'date').toDate();
  const totalLeads = parseInt(data.totalConversions);
  const totalSpend = parseFloat(data.totalSpend);
  const ctr = isFinite(data.totalClicks / data.totalImpressions)
    ? data.totalClicks / data.totalImpressions
    : 0;
  const conversionRate = isFinite(
    data.totalConversions / data.totalInteractions,
  )
    ? data.totalConversions / data.totalInteractions
    : 0;
  const averageCpc = isFinite(totalSpend / data.totalClicks)
    ? totalSpend / data.totalClicks
    : 0;
  const costPerLead = isFinite(totalSpend / totalLeads)
    ? totalSpend / totalLeads
    : 0;

  return {
    id: stat.id,
    ...data,
    ctr,
    conversionRate,
    averageCpc,
    totalSpend,
    costPerLead,
    totalLeads,
    date,
  };
};

function* fetchDailyInsightsSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    let dailyInsightsSnaps = null;

    const db = getFirestore(rsf.app);
    const baseConditions = [
      where('date', '>=', startDate),
      where('date', '<=', endDate),
      where('companyId', '==', companyId),
      orderBy('date', 'desc'),
    ];
    const insightQuery = query(
      collection(db, 'dailyGoogleCampaignInsights'),
      ...baseConditions,
    );

    if (!filters || isNullish(filters)) {
      dailyInsightsSnaps = yield call(
        rsf.firestore.getCollection,
        insightQuery,
      );
      dailyInsightsSnaps = [dailyInsightsSnaps];
    } else {
      let campaigns = null;
      if (filters.sector && !filters.campaignIds) {
        campaigns = yield select(selectCampaignsBySector(filters.sector));
        campaigns = campaigns.map((campaign) => campaign.id);
        campaigns = arrayChunks(campaigns, 10);
      } else campaigns = arrayChunks(filters.campaignIds, 10);

      dailyInsightsSnaps = yield all(
        campaigns.map((campaignId) =>
          call(
            rsf.firestore.getCollection,
            query(
              collection(db, 'dailyGoogleCampaignInsights'),
              ...[...baseConditions, where('campaignId', 'in', campaignId)],
            ),
          ),
        ),
      );
    }

    let dailyInsights = [];

    dailyInsightsSnaps.forEach((dailyInsightsSnap) => {
      dailyInsightsSnap.forEach((stat) => {
        const data = stat.data();
        dailyInsights.push(dailyInsightTransformer(stat, data));
      });
    });

    yield put(
      dailyInsightsActions.fetchDailyInsightsSuccess(
        dailyInsights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(dailyInsightsActions.fetchDailyInsightsFailure(error));
  }
}

function* dailyInsightsSaga() {
  yield all([
    takeEvery(
      types.DAILY_GOOGLE_CAMPAIGN_INSIGHTS.REQUEST,
      fetchDailyInsightsSaga,
    ),
  ]);
}

export default dailyInsightsSaga;
