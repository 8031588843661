import { createSelector } from 'reselect';
import { positiveMonetizationItems } from './monetizationItem';
import { getDates, dateIsInRange } from '../helpers/sharedFunction';
import jsonLogic from 'json-logic-js';
import moment from 'moment';
import colors from '../components/CommonStyle/colors';

export const selectLead = (id) =>
  createSelector(convertBirthdayLead, (leads) => {
    if (!id) return null;

    const lead = leads.filter((lead) => lead.id === id);
    return lead.length > 0 ? lead[0] : null;
  });

export const selectLeadByQuery = (logic) =>
  createSelector(
    (state) => state.Leads.leads,
    (leads) => leads.filter((lead) => jsonLogic.apply(logic, lead)),
  );

export const selectLeadByQualified = (status) =>
  createSelector(
    (state) => state.Leads.leads,
    (leads) => leads.filter(({ qualified }) => qualified === status),
  );

export const selectNewLeads = createSelector(
  (state) => state.Leads.leads,
  (leads) =>
    leads.filter((lead) => !lead.marketingCampaignId && !lead.wasDuplicated),
);

const convertBirthdayLead = createSelector(
  (state) => state.Leads.leads,
  (leads) =>
    leads.map((lead) => ({
      ...lead,
      ...(lead.birthday && { birthday: new Date(lead.birthday) }),
    })),
);

export const countLeads = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.length,
);

export const countNewLeads = createSelector(
  selectNewLeads,
  (leads) => leads.length,
);

export const countLeadsOnDanteCallCenter = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => lead.sentToDante === 'yes').length,
);

export const countLeadsOnChiedimiCallCenter = createSelector(
  (state) => state.Leads.leads,
  (leads) =>
    leads.filter(
      (lead) =>
        (lead.qualified === 'readyToQualify' ||
          lead.qualified === 'completed' ||
          lead.qualified === 'expired') &&
        !lead.danteLeadId,
    ).length,
);

export const countLeadsOnCallCenter = createSelector(
  countLeadsOnDanteCallCenter,
  countLeadsOnChiedimiCallCenter,
  (dante, chiedimi) => dante + chiedimi,
);

export const leadsWithErrors = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => lead.error && lead.error !== ''),
);

export const leadsWithPerson = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => lead.personId && lead.personId !== 'new'),
);

export const leadsWithoutPerson = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => !lead.personId || lead.personId === 'new'),
);

export const noTimingLeads = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => lead.qualified === 'expired'),
);

export const countNoTimingLeads = createSelector(
  noTimingLeads,
  (leads) => leads.length,
);

export const certifiedLeads = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => lead.verifiedPhoneNumber),
);

export const countCertifiedLeads = createSelector(
  certifiedLeads,
  (leads) => leads.length,
);

export const facebookLeads = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => lead.source === 'facebook'),
);

export const countFacebookLeads = createSelector(
  facebookLeads,
  (leads) => leads.length,
);

export const googleAdsLeads = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => lead.source === 'googleAds'),
);

export const countGoogleAdsLeads = createSelector(
  googleAdsLeads,
  (leads) => leads.length,
);

export const leadWithCosts = createSelector(
  (state) => state.Leads.leads,
  (leads) => leads.filter((lead) => !!lead.cost),
);

export const countLeadWithCosts = createSelector(
  leadWithCosts,
  (leads) => leads.length,
);

export const totalCosts = createSelector(leadWithCosts, (leads) =>
  leads.map((lead) => lead.cost).reduce((a, b) => a + b, 0),
);

export const countLeadWithPositiveMonetizationItems = createSelector(
  positiveMonetizationItems(),
  facebookLeads,
  googleAdsLeads,
  leadWithCosts,
  (monetizationItems, fbLeads, googleLeads, leadWithCosts) => {
    const leadIds = [...fbLeads, ...googleLeads, ...leadWithCosts].map(
      ({ id }) => id,
    );
    const uniquePositiveLeadsIds = [
      ...new Set(monetizationItems.map(({ leadId }) => leadId)),
    ];
    return uniquePositiveLeadsIds.filter((leadId) => leadIds.includes(leadId))
      .length;
  },
);

export const drawSeriesDailyGraph = (dateRange, t) =>
  createSelector(
    (state) => state.Leads.leads,
    (leads) => {
      const { startDate, endDate } = dateRange;

      const dates = getDates(startDate.toDate(), endDate.toDate());

      let dataGraph = {
        series: [
          {
            name: t('Leads'),
            data: [],
            type: 'line',
            color: colors.dark,
          },
        ],
        max: -9999999999,
        min: 99999999999,
      };

      dates.forEach((day) => {
        const startDay = moment(day).utc().startOf('day').toDate();
        const endDay = moment(day).utc().endOf('day').toDate();

        const countLeads = leads.filter(({ createdAt }) =>
          dateIsInRange(new Date(createdAt), startDay, endDay),
        ).length;

        dataGraph.series[0].data.push([day.getTime(), countLeads]);

        dataGraph.max = Math.max(dataGraph.max, countLeads);
        dataGraph.min = Math.min(dataGraph.min, countLeads);
      });
      return dataGraph;
    },
  );
