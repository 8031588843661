const statsTypes = {
    RESET_COMPANY_STATE: 'RESET_COMPANY_STATE',
    CREATE_COMPANY: {
      REQUEST: 'CREATE_COMPANY.REQUEST',
      SUCCESS: 'CREATE_COMPANY.SUCCESS',
      FAILURE: 'CREATE_COMPANY.FAILURE',
    },
    UPDATE_COMPANY: {
      REQUEST: 'UPDATE_COMPANY.REQUEST',
      SUCCESS: 'UPDATE_COMPANY.SUCCESS',
      FAILURE: 'UPDATE_COMPANY.FAILURE',
    },
    SYNC_COMPANIES: {
      REQUEST: 'SYNC_COMPANIES.REQUEST',
      SUCCESS: 'SYNC_COMPANIES.SUCCESS',
      FAILURE: 'SYNC_COMPANIES.FAILURE',
    },
    FETCH_COMPANY: {
      REQUEST: 'FETCH_COMPANY.REQUEST',
      SUCCESS: 'FETCH_COMPANY.SUCCESS',
      FAILURE: 'FETCH_COMPANY.FAILURE',
    },
  };
  
  export default statsTypes;
  
