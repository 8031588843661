import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import sagaPlugin from 'reactotron-redux-saga';

let reactotron = Reactotron.configure() // we can use plugins here -- more on this later
  .use(reactotronRedux())
  .use(sagaPlugin());

if (process.env.NODE_ENV !== 'production') {
  reactotron.connect(); // let's connect!
}

export default reactotron;
