import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px 0;
  align-items: center;
  justify-content: space-around;
  display: flex;
  float: none;
`;

const CenterBox = ({ height, children }) => (
  <Container style={{ height: `${height}px` }}>{children}</Container>
);

export default CenterBox;
